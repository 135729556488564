import { Box, Button } from '@mui/material';
import { add, sub } from 'date-fns';
import { FC } from 'react';
import { DatePickerWithDataLookup } from './DatePickerWithDataLookup';

export interface IDateScrollerProps {
    date: Date;
    updateDate: (updatedDate: Date) => void;
    fullWidth?: boolean;
    daysWithData?: string[];
    handleOnMonthChange?: (newMonth: Date) => void;
}

export const DateScroller: FC<IDateScrollerProps> = ({ date, updateDate, fullWidth, daysWithData, handleOnMonthChange }) => {

    return (
        <Box display='flex' flexWrap='nowrap'>
            <Button
                size='small'
                onClick={() => {
                    updateDate(sub(date, { days: 1 }));
                }}>
                {'<'}
            </Button>
            <Box flexGrow={fullWidth ? 1 : undefined}>
                <DatePickerWithDataLookup
                    date={date}
                    updateDate={updateDate}
                    daysWithData={daysWithData}
                    handleOnMonthChange={handleOnMonthChange}
                />
            </Box>
            <Button
                size='small'
                onClick={() => {
                    updateDate(add(date, { days: 1 }));
                }}>
                {'>'}
            </Button>
        </Box>
    );
};

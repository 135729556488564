import { AttachMoney } from '@mui/icons-material';
import { FormControl, FormHelperText, FormLabel, Grid, MenuItem, Select, Typography } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { DumpLineItemDto, QuoteDto, DumpRateDto, OrderDto } from '../../../../../dtos';
import { useArchiveDumpLineItemDeleteFromDatabaseByIdMutation, useLazyGetDumpRateQuery } from '../../../../../store/generated/generatedApi';
import { SlimFormHeader } from '../../../../Core/SlimFormHeader';
import { IFormProps, LoadingIndicator, FormSection, FormNumberInput, IEntityAutocomplete, FormInput } from '../../../../CoreLib/library';
import { useDumpLineItemForm } from './useDumpLineItemForm';
import { dumpTypes, formatCurrency } from '../../../../../util';
import { EquipmentTypeSelect, SiteSelect, UnitOfMeasureSelect } from '../../../../CommonInputs';

export interface IDumpLineItemFormProps extends IFormProps<DumpLineItemDto> {
    quote?: QuoteDto;
    order?: OrderDto;
}

export const DumpLineItemForm: FC<IDumpLineItemFormProps> = (props) => {
    const { isLoading, quote, order } = props;
    const {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleQuoteChange,
        handleEquipmentTypeChange,
        handleRateChange,
        handleQuantityChange,
        handleDumpTypeChange,
        handleDumpRateChange,
        handleDriverMemoChange,
        formQuote,
        formEquipmentType,
        formDumpRate,
        formRate,
        formQuantity,
        formIsActive,
        formDumpType,
        formDriverMemo,
        formSite,
        formUnitOfMeasure,
        formCost,
        formTotal
    } = useDumpLineItemForm(props);


    const [getDumpSites, { data: dumpSites, isLoading: dumpSitesLoading }] = useLazyGetDumpRateQuery();

    useEffect(() => {
        if (quote && !formQuote) {
            handleQuoteChange(quote);
        }
    }, [formQuote, handleQuoteChange, quote]);

    useEffect(() => {
        if (formDumpType) {
            getDumpSites({ type: formDumpType, searchText: '', sortKey: 'TYPE', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false });
        }
    }, [formDumpType, getDumpSites]);

    const currentDumpLineItemId = useMemo(() => {
        return props.initValues?.id;
    }, [props.initValues?.id]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Grid container direction='column'>
            <SlimFormHeader
                objectName='Dump Line Item'
                permissionsTypeName={'lineItem'}
                icon={<AttachMoney />}
                title={formQuote ? `Dump Line Item for Quote #${formQuote.quoteNumber}` : 'New Dump Line Item'}
                breadcrumbs={
                    order
                        ? [
                            { label: 'Home', navLink: '/' },
                            { label: 'Jobs', navLink: '/jobs' },
                            { label: `Job #${quote?.quoteNumber}`, navLink: `/job/${order?.jobId}` },
                            { label: `Order #${order?.orderNumber}`, navLink: `/job/${order?.jobId}/order/${order?.id}` },
                        ]
                        : [
                            { label: 'Home', navLink: '/' },
                            { label: 'Quotes', navLink: '/quotes' },
                            { label: `${quote?.customer?.name ?? ''} (Quote #${quote?.quoteNumber ?? 'Quote'})`, navLink: `/quote/${quote?.id}` },
                            { label: `Line Items`, navLink: `/quote/${quote?.id}/lineItems` },
                        ]
                }
                isActive={formIsActive}
                id={currentDumpLineItemId}
                isFormDirty={isFormDirty}
                handleCancel={handleCancel}
                handleSave={handleSave}
                entityNameSingular={'dump line item'}
                canSave={true}
                deleteMutation={useArchiveDumpLineItemDeleteFromDatabaseByIdMutation}
            />
            <Grid item container direction='row' xs={12} spacing={2} px={2}>
                <Grid item xs={8}>
                    <FormSection>
                        <Grid item>
                            <Typography variant='h2'>General</Typography>
                        </Grid>
                        <Grid item container direction='row' spacing={3}>
                            <Grid item xs={12} sm={6} md={5} lg={4} xl={3}>
                                <EquipmentTypeSelect
                                    selectedEquipmentTypeId={formEquipmentType?.id ?? ''}
                                    handleSelectedEquipmentTypeChange={handleEquipmentTypeChange}
                                    required
                                    errorMessage={fieldErrors.get('equipmentTypeId')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl error={!!fieldErrors.get('dumpType')} fullWidth required>
                                    <FormLabel>Type</FormLabel>
                                    <Select value={formDumpType ?? ''} onChange={handleDumpTypeChange}>
                                        {dumpTypes.map((type) => {
                                            return (
                                                <MenuItem key={type} value={type}>
                                                    {type}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <FormHelperText>{fieldErrors.get('dumpType')}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={5} xl={4}>
                                <FormControl error={!!fieldErrors.get('dumpRateId')} fullWidth required disabled={!formDumpType}>
                                    <FormLabel>Site + Pricing</FormLabel>
                                    <IEntityAutocomplete
                                        options={dumpSites?.pageResults}
                                        onChange={(e, value) => {
                                            handleDumpRateChange(value ?? null);
                                        }}
                                        value={formDumpRate}
                                        isLoading={dumpSitesLoading}
                                        getOptionLabel={(option: DumpRateDto) =>
                                            `${option.site?.code} - Cost: ${formatCurrency(option.cost)} - ${option.unitOfMeasure}`
                                        }
                                        disabled={!formDumpType}
                                        error={!!fieldErrors.get('dumpRateId')}
                                    />
                                    <FormHelperText>{fieldErrors.get('dumpRateId')}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant='h2'>Pricing</Typography>
                        </Grid>
                        <Grid item container direction='row' spacing={3}>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <UnitOfMeasureSelect
                                    selectedUnit={formUnitOfMeasure ?? ''}
                                    handleSelectedUnitChange={() => { }}
                                    errorMessage={fieldErrors.get('unitOfMeasure')}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                                <FormNumberInput
                                    value={formCost}
                                    onChange={() => { }}
                                    label='Cost'
                                    name='cost'
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2.5} xl={2}>
                                <FormNumberInput
                                    value={formQuantity ?? ''}
                                    onChange={handleQuantityChange}
                                    label='Quantity'
                                    name='quantity'
                                    errorText={fieldErrors.get('quantity')}
                                    error={!!fieldErrors.get('quantity')}
                                    fullWidth
                                    disabled={isLoading}
                                    inputProps={{ min: 0 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2.5} xl={2}>
                                <FormNumberInput
                                    value={formRate}
                                    onChange={handleRateChange}
                                    label='Rate'
                                    name='rate'
                                    errorText={fieldErrors.get('rate')}
                                    error={!!fieldErrors.get('rate')}
                                    fullWidth
                                    required
                                    disabled={isLoading}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
                                <FormNumberInput value={formTotal ?? ''} onChange={() => { }} label='Total' name='total' fullWidth disabled isCurrency />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant='h2'>Hauling</Typography>
                        </Grid>
                        <Grid item container direction='row' spacing={3}>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                <SiteSelect
                                    selectedSiteId={formSite?.id ?? ''}
                                    handleSelectedSiteChange={() => { }}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    value={formDriverMemo ?? ''}
                                    onChange={handleDriverMemoChange}
                                    label='Driver Memo'
                                    name='driverMemo'
                                    fullWidth
                                    rows={3}
                                />
                            </Grid>
                        </Grid>
                    </FormSection>
                </Grid>
                <Grid item xs={4}>
                    <Grid item container direction='column' spacing={3}>
                        <Grid item>
                            <FormSection>
                                <Grid item>
                                    <Typography variant='h2'>
                                        {!formDumpRate ? 'No Site Selected' : `${formDumpRate?.site?.code} - ${formDumpRate?.site?.name}`}
                                    </Typography>
                                </Grid>
                                {formDumpRate && (
                                    <Grid item container direction='column'>
                                        <Grid item>{formDumpRate.site?.addressLine1}</Grid>
                                        <Grid item>{formDumpRate.site?.addressLine2}</Grid>
                                        <Grid item>
                                            {formDumpRate.site?.city ?? '(City)'}, {formDumpRate.site?.state ?? '(State)'},{' '}
                                            {formDumpRate.site?.zipCode ?? '(Zip Code)'}
                                        </Grid>
                                        <Grid item>{formDumpRate.site?.country ?? '(Country)'}</Grid>
                                    </Grid>
                                )}
                            </FormSection>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

import { useCallback, useEffect, useMemo, useState } from "react";
import { FreightBillingLineItemDto, LineItemBillingRatesDto, OrderLineItemDto, OrderReviewLineItemDto, PricingMethodType, RateToggle } from "../../../../dtos";
import { IFormFieldValidationConfig, isNotBlank, runFormValidation } from "../../../CoreLib/library";
import { emptyGuid, getDefaultPricingMethod, LineItemTypes } from "../../../../util";
import { useCreateBillingRatesLineItemBillingRatesMutation } from "../../../../store/generated/generatedApi";

export type BILLING_RATE_FORM_VALIDATED_FIELD = 'freightBillNumber' | 'lineItemRate' | 'additionalRate' | 'haulingRate' | 'flatHaulingRate' | 'driverRate' | 'flatDriverRate' | 'brokerRate' | 'flatBrokerRate' | 'rateToggle' | 'quantity' | 'pricingMethodType';

export interface IBillingRateFormProps {
    billingRates: FreightBillingLineItemDto | LineItemBillingRatesDto;
    lineItem: OrderReviewLineItemDto | OrderLineItemDto;
    isFob: boolean;
    isOrder: boolean;
    ignoreFields?: BILLING_RATE_FORM_VALIDATED_FIELD[];
}

export function useBillingRateForm(props: IBillingRateFormProps) {
    const { billingRates, lineItem: orderLineItem, isFob, isOrder, ignoreFields = [] } = props;
    const [getBillingRates] = useCreateBillingRatesLineItemBillingRatesMutation();

    const [freightBillNumber, setFreightBillNumber] = useState<string | undefined>((billingRates as any).freightBillNumber ?? '');
    const [lineItemRate, setLineItemRate] = useState<number | undefined>(billingRates.lineItemRate ?? orderLineItem.billingRates?.lineItemRate);
    const [additionalRate, setAdditionalRate] = useState<number | undefined>(billingRates.additionalRate ?? orderLineItem.billingRates?.additionalRate);
    const [haulingRate, setHaulingRate] = useState<number | undefined>(billingRates.haulingRate ?? orderLineItem.billingRates?.haulingRate);
    const [flatHaulingRate, setFlatHaulingRate] = useState<number | undefined>(billingRates.flatHaulingRate ?? orderLineItem.billingRates?.flatHaulingRate);
    const [driverRate, setDriverRate] = useState<number | undefined>(billingRates.driverRate ?? orderLineItem.billingRates?.driverRate);
    const [flatDriverRate, setFlatDriverRate] = useState<number | undefined>(billingRates.flatDriverRate ?? orderLineItem.billingRates?.flatDriverRate);
    const [brokerRate, setBrokerRate] = useState<number | undefined>(billingRates.brokerRate ?? orderLineItem.billingRates?.brokerRate);
    const [flatBrokerRate, setFlatBrokerRate] = useState<number | undefined>(billingRates.flatBrokerRate ?? orderLineItem.billingRates?.flatBrokerRate);
    const [rateToggle, setRateToggle] = useState(billingRates.rateToggle ?? orderLineItem.billingRates?.rateToggle ?? RateToggle.Standard);
    const [quoteRate, setQuoteRate] = useState<number | undefined>(billingRates.quoteRate ?? orderLineItem.billingRates?.quoteRate);
    const [salesPrice, setAmount] = useState<number | undefined>(billingRates.salesPrice ?? orderLineItem.billingRates?.salesPrice);
    const [flatRateApplied, setFlatRateApplied] = useState<number | undefined>(billingRates.flatRateApplied);
    const [quantity, setQuantity] = useState<number | undefined>(billingRates.quantity ?? orderLineItem.billingRates?.quantity);
    const [haulingTotalRate, setTotalHaulingRate] = useState<number | undefined>(billingRates.haulingRate ?? orderLineItem.billingRates?.haulingRate);
    const [haulingTotalFlatRate, setTotalHaulingFlatRate] = useState<number | undefined>(
        billingRates.flatHaulingRate ?? orderLineItem.billingRates?.flatHaulingRate
    );
    const [calculate, setCalculate] = useState<boolean>(false);
    const [unitOfMeasure, setUnitOfMeasure] = useState<string | undefined>(billingRates.unitOfMeasure ?? orderLineItem.billingRates?.unitOfMeasure);
    const [isMaterialLineItemType] = useState<boolean>(orderLineItem.lineItem?.type === LineItemTypes.Material);
    const defaultPricingMethodType = useMemo(() => {
        return getDefaultPricingMethod(orderLineItem.lineItem);
    }, [orderLineItem.lineItem]);
    const [pricingMethodType, setPricingMethodType] = useState<PricingMethodType | undefined>(billingRates.pricingMethodType ?? orderLineItem.billingRates?.pricingMethodType ?? defaultPricingMethodType);
    const [isMaterialOnly, setIsMaterialOnly] = useState<boolean>(billingRates.isMaterialOnly ?? orderLineItem.billingRates?.isMaterialOnly ?? false);

    useEffect(() => {
        setFreightBillNumber((billingRates as any).freightBillNumber ?? '');
        setLineItemRate(billingRates.lineItemRate ?? orderLineItem.billingRates?.lineItemRate);
        setAdditionalRate(billingRates.additionalRate ?? orderLineItem.billingRates?.additionalRate);
        setHaulingRate(billingRates.haulingRate ?? orderLineItem.billingRates?.haulingRate);
        setFlatHaulingRate(billingRates.flatHaulingRate ?? orderLineItem.billingRates?.flatHaulingRate);
        setDriverRate(billingRates.driverRate ?? orderLineItem.billingRates?.driverRate);
        setFlatDriverRate(billingRates.flatDriverRate ?? orderLineItem.billingRates?.flatDriverRate);
        setBrokerRate(billingRates.brokerRate ?? orderLineItem.billingRates?.brokerRate);
        setFlatBrokerRate(billingRates.flatBrokerRate ?? orderLineItem.billingRates?.flatBrokerRate);
        setRateToggle(billingRates.rateToggle ?? orderLineItem.billingRates?.rateToggle ?? RateToggle.Standard);
        setQuoteRate(billingRates.quoteRate ?? orderLineItem.billingRates?.quoteRate);
        setAmount(billingRates.salesPrice ?? orderLineItem.billingRates?.salesPrice);
        setFlatRateApplied(billingRates.flatRateApplied);
        setQuantity(billingRates.quantity ?? orderLineItem.billingRates?.quantity);
        setTotalHaulingRate(billingRates.haulingRate ?? orderLineItem.billingRates?.haulingRate);
        setTotalHaulingFlatRate(billingRates.flatHaulingRate ?? orderLineItem.billingRates?.flatHaulingRate);
        setUnitOfMeasure(billingRates.unitOfMeasure ?? orderLineItem.billingRates?.unitOfMeasure);
        setPricingMethodType(billingRates.pricingMethodType ?? orderLineItem.billingRates?.pricingMethodType ?? defaultPricingMethodType);
        setIsMaterialOnly(billingRates.isMaterialOnly ?? orderLineItem.billingRates?.isMaterialOnly ?? false);
    }, [billingRates, orderLineItem.billingRates?.additionalRate, orderLineItem.billingRates?.salesPrice, orderLineItem.billingRates?.driverRate, orderLineItem.billingRates?.flatDriverRate, orderLineItem.billingRates?.flatHaulingRate, orderLineItem.billingRates?.flatBrokerRate, orderLineItem.billingRates?.haulingRate, orderLineItem.billingRates?.lineItemRate, orderLineItem.billingRates?.quantity, orderLineItem.billingRates?.quoteRate, orderLineItem.billingRates?.rateToggle, orderLineItem.billingRates?.brokerRate, orderLineItem.billingRates?.unitOfMeasure, orderLineItem.billingRates?.pricingMethodType, defaultPricingMethodType, orderLineItem.billingRates?.isMaterialOnly]);

    const calculateBillingRates = useCallback((): Promise<LineItemBillingRatesDto> => {
        return getBillingRates({
            id: emptyGuid,
            isActive: true,
            createdOn: new Date(),
            quantity,
            lineItemRate,
            additionalRate,
            haulingRate,
            flatHaulingRate,
            driverRate,
            flatDriverRate,
            brokerRate,
            flatBrokerRate,
            rateToggle,
            flatRateApplied,
            unitOfMeasure: orderLineItem.lineItem?.unitOfMeasure,
            isFobQuote: isFob,
            lineItemType: orderLineItem.lineItem?.type ?? '',
            pricingMethodType
        }).then((response) => {
            return (response as any).data as LineItemBillingRatesDto;
        });
    }, [orderLineItem, additionalRate, brokerRate, driverRate, flatBrokerRate, flatDriverRate, flatHaulingRate, flatRateApplied, getBillingRates, haulingRate, isFob, lineItemRate, pricingMethodType, quantity, rateToggle]);

    const calculateRate = useCallback(async () => {
        const billingRates = await calculateBillingRates();
        setQuoteRate(billingRates.quoteRate);
        setAmount(billingRates.salesPrice);
        setFlatRateApplied(billingRates.flatRateApplied);
        if (orderLineItem?.lineItem?.type === LineItemTypes.Material) {
            setTotalHaulingRate(billingRates.haulingRate);
            setTotalHaulingFlatRate(billingRates.flatHaulingRate);
        }
    }, [calculateBillingRates, orderLineItem]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof any, string>>(
        new Map([
            ['freightBillNumber', ''],
            ['lineItemRate', ''],
            ['additionalRate', ''],
            ['haulingRate', ''],
            ['flatHaulingRate', ''],
            ['driverRate', ''],
            ['flatDriverRate', ''],
            ['brokerRate', ''],
            ['flatBrokerRate', ''],
            ['rateToggle', ''],
            ['quantity', ''],
            ['pricingMethodType', ''],
        ])
    );

    const isMaterialOrSiteHauling = useMemo(() => {
        return orderLineItem.lineItem?.type === LineItemTypes.Material || orderLineItem.lineItem?.type === LineItemTypes.SiteHauling;
    }, [orderLineItem.lineItem?.type]);

    const isMaterialOrDumpLineItem = useMemo(() => {
        return orderLineItem.lineItem?.type === LineItemTypes.Material || orderLineItem.lineItem?.type === LineItemTypes.Dump;
    }, [orderLineItem.lineItem?.type]);

    const formFieldValidators = useMemo(
        () =>
            new Map<keyof any, IFormFieldValidationConfig>([
                ['freightBillNumber', {
                    validators: isOrder || ignoreFields.includes('freightBillNumber') ? [] : [isNotBlank],
                    errorMessageEntityName: 'Freight Bill Number',
                }],
                ['quantity', {
                    validators: isMaterialOrDumpLineItem && !ignoreFields.includes('quantity') ? [isNotBlank] : [],
                    errorMessageEntityName: 'Quantity',
                }],
                ['lineItemRate', {
                    validators: ignoreFields.includes('lineItemRate') ? [] : [isNotBlank],
                    errorMessageEntityName: 'Line Item Rate',
                }],
                ['additionalRate', {
                    validators: isMaterialOrSiteHauling && !ignoreFields.includes('additionalRate') ? [isNotBlank] : [],
                    errorMessageEntityName: 'Additional Rate',
                }],
                ['pricingMethodType', {
                    validators: isMaterialOrSiteHauling && !ignoreFields.includes('pricingMethodType') ? [isNotBlank] : [],
                    errorMessageEntityName: 'Pricing Method Type',
                }],
                ['haulingRate', {
                    validators: isMaterialOrSiteHauling && !isMaterialOnly && rateToggle !== RateToggle.Flat && !ignoreFields.includes('haulingRate') ? [isNotBlank] : [],
                    errorMessageEntityName: 'Hauling Rate',
                }],
                ['flatHaulingRate', {
                    validators: isMaterialOrSiteHauling && !isMaterialOnly && rateToggle !== RateToggle.Standard && !ignoreFields.includes('flatHaulingRate') ? [isNotBlank] : [],
                    errorMessageEntityName: 'Flat Hauling Rate',
                }],
                ['driverRate', {
                    validators: isMaterialOrSiteHauling && !isMaterialOnly && rateToggle !== RateToggle.Flat && !ignoreFields.includes('driverRate') ? [isNotBlank] : [],
                    errorMessageEntityName: 'Driver Rate',
                }],
                ['flatDriverRate', {
                    validators: isMaterialOrSiteHauling && !isMaterialOnly && rateToggle !== RateToggle.Standard && !ignoreFields.includes('flatDriverRate') ? [isNotBlank] : [],
                    errorMessageEntityName: 'Flat Driver Rate',
                }],
                ['brokerRate', {
                    validators: isMaterialOrSiteHauling && !isMaterialOnly && rateToggle !== RateToggle.Flat && !ignoreFields.includes('brokerRate') ? [isNotBlank] : [],
                    errorMessageEntityName: 'Broker Rate',
                }],
                ['flatBrokerRate', {
                    validators: isMaterialOrSiteHauling && !isMaterialOnly && rateToggle !== RateToggle.Standard && !ignoreFields.includes('flatBrokerRate') ? [isNotBlank] : [],
                    errorMessageEntityName: 'Flat Broker Rate',
                }],
                ['rateToggle', {
                    validators: ignoreFields.includes('rateToggle') ? [] : [isNotBlank],
                    errorMessageEntityName: 'Rate Toggle',
                }]
            ])
        , [isOrder, ignoreFields, isMaterialOrDumpLineItem, isMaterialOrSiteHauling, isMaterialOnly, rateToggle]
    );

    const getCurrentFormValues = useCallback(() => {
        return {
            ...billingRates,
            freightBillNumber,
            lineItemRate,
            additionalRate,
            haulingRate,
            flatHaulingRate,
            driverRate,
            flatDriverRate,
            brokerRate,
            flatBrokerRate,
            rateToggle,
            flatRateApplied,
            quoteRate,
            salesPrice,
            quantity,
            unitOfMeasure,
            pricingMethodType,
            isMaterialOnly
        };
    }, [
        billingRates,
        freightBillNumber,
        lineItemRate,
        additionalRate,
        haulingRate,
        flatHaulingRate,
        driverRate,
        flatDriverRate,
        brokerRate,
        flatBrokerRate,
        rateToggle,
        flatRateApplied,
        quoteRate,
        salesPrice,
        quantity,
        unitOfMeasure,
        pricingMethodType,
        isMaterialOnly
    ]);

    const validateForm = useCallback(() => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<any>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    }, [formFieldValidators, getCurrentFormValues]);

    const isValid = useCallback(() => {
        return validateForm();
    }, [validateForm]);

    const handleFormValueChange = useCallback((key: any, value: any) => {
        switch (key) {
            case 'freightBillNumber':
                setFreightBillNumber(value);
                break;
            case 'lineItemRate':
                setLineItemRate(value);
                break;
            case 'additionalRate':
                setAdditionalRate(value);
                break;
            case 'haulingRate':
                setHaulingRate(value);
                break;
            case 'flatHaulingRate':
                setFlatHaulingRate(value);
                break;
            case 'driverRate':
                setDriverRate(value);
                break;
            case 'flatDriverRate':
                setFlatDriverRate(value);
                break;
            case 'brokerRate':
                setBrokerRate(value);
                break;
            case 'flatBrokerRate':
                setFlatBrokerRate(value);
                break;
            case 'rateToggle':
                setRateToggle(value);
                break;
            case 'quantity':
                setQuantity(value);
                break;
            case 'pricingMethodType':
                setPricingMethodType(value);
                if (value === PricingMethodType.MaterialOnly) {
                    setIsMaterialOnly(true);
                } else {
                    setIsMaterialOnly(false);
                }
                break;
            case 'isMaterialOnly':
                setIsMaterialOnly(value);
                if (value) {
                    setPricingMethodType(PricingMethodType.MaterialOnly);
                } else {
                    setPricingMethodType(defaultPricingMethodType);
                }
                break;
            default:
                break;
        }

        setCalculate(true);
    }, [defaultPricingMethodType]);

    useEffect(() => {
        if (calculate) {
            calculateRate();
            validateForm();
            setCalculate(false);
        }
    }, [calculate, calculateRate, validateForm]);

    return {
        freightBillNumber,
        lineItemRate,
        additionalRate,
        haulingRate,
        flatHaulingRate,
        driverRate,
        flatDriverRate,
        brokerRate,
        flatBrokerRate,
        rateToggle,
        quoteRate,
        salesPrice,
        flatRateApplied,
        quantity,
        getCurrentFormValues,
        handleFormValueChange,
        fieldErrors,
        isValid,
        haulingTotalRate,
        haulingTotalFlatRate,
        isMaterialLineItemType,
        unitOfMeasure,
        pricingMethodType,
        isMaterialOnly,
    }
}

import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableColumn, PaginatedProps, StandardListViewV2, UserPermissionContext, useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../Components/CoreLib/library';
import { JobDto, OrderDto } from '../../dtos';
import {
    useArchiveOrderBulkDeleteMutation,
    useCreateOrderDownloadSelectedOrdersMutation,
    useCreateOrderMutation,
    useGetOrderQuery,
    useOrderBulkArchiveMutation,
    useOrderBulkUnarchiveMutation,
} from '../../store/generated/generatedApi';
import { Link, Typography } from '@mui/material';
import { AddOrderDateModal } from './AddOrderDateModal';
import { exportToPdf } from '../../util';
import { Download } from '@mui/icons-material';

export interface IOrdersListViewProps {
    job: JobDto;
}

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'ORDER_DATE',
    sortAsc: false,
    page: 0,
    pageSize: 25,
};

export const OrderListView: FC<IOrdersListViewProps> = (props) => {
    const { job } = props;
    const navigate = useNavigate();
    const { permissions } = useContext(UserPermissionContext);
    const [createOrder, { data, isError, isSuccess, reset }] = useCreateOrderMutation();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [downloadSelectedPdfs, { isLoading: isDownloadingPdfs }] = useCreateOrderDownloadSelectedOrdersMutation();

    useSuccessfulCreateSnackbar('Order', isSuccess, () => {
        navigate(`/job/${job.id}/order/${data?.id}`)
    });

    useFailedCreateSnackbar('order', isError, reset)

    const handleSave = (values: OrderDto) => {
        createOrder(values);
    };

    const handleCancel = () => {
        setIsAddModalOpen(false);
    };

    const tableColumns: DataTableColumn<OrderDto>[] = useMemo(
        () => [
            { key: 'orderNumber', label: 'Order #', sortKey: 'ORDER_NUMBER' },
            {
                key: 'orderDate',
                label: 'Order Date',
                sortKey: 'ORDER_DATE',
                fieldMapper: (row) => (row.orderDate ? new Date(row.orderDate.toString()).toLocaleDateString() : ''),
            },
            { key: 'orderLineItemCount', label: 'Line Items Count', sortKey: 'LINE_ITEM_COUNT' },
            {
                key: 'quoteNumber',
                label: job.currentQuote?.appraisalType === 0 ? 'Quote #' : 'Estimate #',
                sortKey: 'QUOTE_NUMBER',
                fieldMapper: (row) => (
                    <Typography>
                        {permissions.includes('edit:quote') ? (
                            <Link
                                href={`/quote/${row.quoteId}`}
                                variant='body2'
                                onClick={() => {
                                    navigate(`/quote/${row.quoteId}`);
                                }}>
                                {row.quote?.quoteNumber}
                            </Link>
                        ) : (
                            <>{row.quote?.quoteNumber}</>
                        )}
                    </Typography>
                ),
            },
            { key: 'status', label: 'Status', sortKey: 'STATUS' }
        ],
        [navigate, permissions, job.currentQuote?.appraisalType]
    );

    const handleCreateNew = useCallback(() => {
        setIsAddModalOpen(true);
    }, []);

    const handleTableEdit = useCallback((id: string) => {
        navigate(`/job/${job.id}/order/${id}`);
    }, [job.id, navigate]);

    const handleDownload = useCallback((selectedItems: OrderDto[]) => {
        if (selectedItems.length > 0) {
            const selectedIds = selectedItems.map(x => x.id);
            downloadSelectedPdfs({
                orderIds: selectedIds,
            }).then((response: any) => {
                if (response.data) {
                    exportToPdf(response.data, `Orders.pdf`.replace(/\s+/g, ''));
                }
            })
        }
    }, [downloadSelectedPdfs]);

    return (
        <>
            {isAddModalOpen && <AddOrderDateModal onSave={handleSave} onClose={handleCancel} open={isAddModalOpen} job={job} />}
            {job.currentQuote !== null && (
                <StandardListViewV2
                    headerIcon={<></>}
                    headerTitle=''
                    breadCrumbs={[]}
                    defaultPaginationProps={defaultPaginationProps}
                    getDataQuery={useGetOrderQuery}
                    isShowActiveToggleVisible
                    inactivateMutation={useOrderBulkArchiveMutation}
                    activateMutation={useOrderBulkUnarchiveMutation}
                    deleteMutation={useArchiveOrderBulkDeleteMutation}
                    tableColumns={tableColumns}
                    entityNameSingular='Order'
                    permissionName='order'
                    handleAdd={handleCreateNew}
                    handleEdit={handleTableEdit}
                    disableDeleteForRecord={(record: OrderDto) => !!record.orderReview}
                    disableInactiveForRecord={(record: OrderDto) => !!record.orderReview}
                    additionalQueryParameters={{ quoteId: job.currentQuote?.id }}
                    additionalActionItems={[
                        {
                            text: 'DOWNLOAD',
                            onClick: (selectedItems: OrderDto[]) => {
                                handleDownload(selectedItems);
                            },
                            icon: <Download />,
                            enabledCheck: (selected: OrderDto[]) => selected.length > 0 && !isDownloadingPdfs,
                            clearSelectedItems: true,
                        }
                    ]}
                />
            )}
        </>
    );
};

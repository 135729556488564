import { CheckCircle, DownloadForOffline, RateReview, Send } from '@mui/icons-material';
import { Box, Checkbox, Chip, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, Link, Paper, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC, ReactElement, useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePrompt } from '../../../Views';
import { InvoiceHistoryButton } from '../../../Views/OrderReview/InvoiceHistoryButton';
import { LineItemDto, OrderReviewDto } from '../../../dtos';
import { ReviewStatus } from '../../../dtos/generated/ReviewStatus';
import { useArchiveOrderReviewDeleteFromDatabaseByIdMutation, useCreateOrderReviewPdfMutation } from '../../../store/generated/generatedApi';
import { ContactSelect, DatePicker } from '../../CommonInputs';
import { SlimFormHeader } from '../../Core/SlimFormHeader';
import { FormInput, IFormProps, UserPermissionContext, useFailedActionSnackbar, useSuccessfulActionSnackbar, userHasPermissions } from '../../CoreLib/library';
import { AddOrderReviewLineItemDialog } from './AddOrderReviewLineItemDialog';
import { OrderReviewLineItemTable } from './OrderReviewLineItemTable';
import { useOrderReviewForm } from './useOrderReviewForm';

export interface IOrderReviewFormProps extends IFormProps<OrderReviewDto> {
    jobId: string;
    orderId: string;
}

export const OrderReviewForm: FC<IOrderReviewFormProps> = (props) => {
    const { isLoading, initValues, jobId, orderId } = props;
    const navigate = useNavigate();
    const { permissions } = useContext(UserPermissionContext);
    const isUserAllowedToReview = userHasPermissions(['create:review', 'edit:review'], permissions, 'or');
    const isUserAllowedToBill = userHasPermissions(['create:billing', 'edit:billing'], permissions, 'or');
    const {
        isFormDirty,
        isFormValid,
        handleSave,
        handleSubmit,
        handleCancel,
        baseFormErrors,
        lineItemErrors,
        dispatchErrors,
        freightErrors,
        handleOrderDateChange,
        handleContactChange,
        handleOrderedByContactChange,
        handleOrderLineItemsChange,
        handleAddNewDispatchReview,
        handleAddNewOrderReviewLineItem,
        handleAddNewFreightBillingLineItem,
        handleRemoveDispatchReview,
        handleRemoveOrderReviewLineItem,
        handleRemoveFreightBillingLineItem,
        handleMemoChange,
        handleIsTaxableChange,
        setFormPoNumber,
        formIsActive,
        formOrderNumber,
        formOrderDate,
        formOrderByContact,
        formContact,
        formOrderLineItems,
        formMemo,
        formStatus,
        formPoNumber,
        formIsTaxable,
        order,
    } = useOrderReviewForm(props, jobId, orderId);

    const [isAddLineItemDialogOpen, setIsAddLineItemDialogOpen] = useState(false);

    const handleCloseAddLineItemDialog = useCallback(() => {
        setIsAddLineItemDialogOpen(false);
    }, []);

    const handleAddOrderReviewLineItemClicked = useCallback(() => {
        setIsAddLineItemDialogOpen(true);
    }, []);

    const handleAddQuoteLineItem = useCallback(
        (lineItem: LineItemDto) => {
            handleAddNewOrderReviewLineItem(lineItem);
            handleCloseAddLineItemDialog();
        },
        [handleCloseAddLineItemDialog, handleAddNewOrderReviewLineItem]
    );

    const handleAddCustomLineItem = useCallback(() => {
        handleAddNewOrderReviewLineItem();
        handleCloseAddLineItemDialog();
    }, [handleCloseAddLineItemDialog, handleAddNewOrderReviewLineItem]);

    const [getInvoicePdf, { isSuccess, isError, error: pdfGenerationError, isLoading: pdfLoading, reset: resetCreatePDf }] = useCreateOrderReviewPdfMutation();
    useSuccessfulActionSnackbar('created', 'quote PDF', isSuccess, resetCreatePDf);
    useFailedActionSnackbar('create', 'quote PDF', isError, resetCreatePDf, pdfGenerationError);

    usePrompt('Are you sure you want to leave this page?\nUnsaved changes will be lost.', isFormDirty());

    const currentOrderReviewId = useMemo(() => {
        return initValues?.id;
    }, [initValues?.id]);

    const generatePdf = useCallback(() => {
        if (!pdfLoading && currentOrderReviewId) {
            getInvoicePdf({ id: currentOrderReviewId }).then((response: any) => {
                if (response?.data) {
                    window.open(response?.data.url, '_blank');
                }
            });
        }
    }, [currentOrderReviewId, getInvoicePdf, pdfLoading]);

    const gridItem = useCallback((content: ReactElement) => {
        return (
            <Grid
                item
                sx={{
                    '--Grid-borderWidth': '1px',
                    border: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                    borderCollapse: 'collapse',
                    flexGrow: '1',
                }}>
                <Box p={2}>{content}</Box>
            </Grid>
        );
    }, []);

    const editDisabled = useMemo(() => {
        if (pdfLoading || isLoading) {
            return true;
        }

        return false;
    }, [isLoading, pdfLoading]);

    const saveDisabled = useMemo(() => {
        if (pdfLoading || isLoading) {
            return true;
        }
        if (formStatus === ReviewStatus.Invoiced && !isUserAllowedToBill) {
            return true;
        }

        return false;
    }, [formStatus, isLoading, isUserAllowedToBill, pdfLoading]);

    const canSubmit = useMemo(() => {
        if (!isFormValid || isLoading) {
            return false;
        }
        if (isUserAllowedToBill) {
            if (!initValues?.readyForBilling) {
                return true;
            }
            const freightBillingLineItems = formOrderLineItems.flatMap((lineItem) =>
                lineItem.dispatches.flatMap((dispatch) => dispatch.freightBillingLineItems)
            );

            if (
                initValues?.readyForBilling &&
                (initValues.invoiceStatus === 'Awaiting Review' || freightBillingLineItems.some((lineItem) => !lineItem!.invoiceId))
            ) {
                return true;
            } else {
                return false;
            }
        }
        if (isUserAllowedToReview) {
            if (!initValues?.readyForBilling && formStatus === ReviewStatus.InReview) {
                return true;
            } else {
                return false;
            }
        }

        return false;
    }, [
        isFormValid,
        isLoading,
        isUserAllowedToBill,
        isUserAllowedToReview,
        initValues?.readyForBilling,
        initValues?.invoiceStatus,
        formOrderLineItems,
        formStatus,
    ]);

    const submit = useCallback(() => {
        handleSubmit(initValues?.readyForBilling ?? false);
    }, [handleSubmit, initValues?.readyForBilling]);

    const currentQuote = useMemo(() => {
        return order?.quote ?? initValues?.job?.currentQuote;
    }, [initValues?.job?.currentQuote, order?.quote]);

    const ReferenceItem = useCallback(
        (itemName: string, itemLinkText: string, itemLink: string) => {
            return (
                <Box display='flex' gap={0.5} flexWrap='nowrap' alignItems='center'>
                    <Typography>{itemName}</Typography>
                    <Link href={itemLink} variant='body2' onClick={() => navigate(itemLink)}>
                        {itemLinkText}
                    </Link>
                </Box>
            );
        },
        [navigate]
    );

    const cityStateZip = useMemo(() => {
        if (currentQuote) {
            return `${currentQuote?.city}, ${currentQuote?.state}, ${currentQuote?.zipCode}`;
        } else {
            return '';
        }
    }, [currentQuote]);

    return (
        <Box display='flex' flexDirection='column'>
            <SlimFormHeader
                objectName='Order'
                permissionsTypeName='order'
                icon={<RateReview />}
                title={`Reviewing Order #${formOrderNumber ?? ' ###'}`} // TODO: update core lib to allow title to be distinct from the last breadcrumb.
                breadcrumbs={[
                    { label: 'Home', navLink: '/' },
                    {
                        label: 'Review',
                        navLink: `/orderReview?date=${format(initValues?.orderDate ? new Date(initValues?.orderDate) : new Date(), 'yyyy-MM-dd')} `,
                    },
                ]}
                isActive={formIsActive}
                id={currentOrderReviewId}
                isFormDirty={isFormDirty}
                handleCancel={handleCancel}
                handleSave={handleSave}
                canSave={!saveDisabled && isFormValid}
                canDelete={!editDisabled}
                entityNameSingular={'order'}
                hideDividers
                deleteConfirmationDialog={{
                    title: 'Delete Order Review?',
                    content: (
                        <Typography>
                            Are you sure you would like to permanently delete this record? It will be deleted immediately and cannot be recovered.
                        </Typography>
                    ),
                }}
                deleteMutation={useArchiveOrderReviewDeleteFromDatabaseByIdMutation}
                submit={{
                    handleSubmit: () => submit(),
                    canSubmit: canSubmit,
                    submitText: !initValues?.readyForBilling ? 'Send to Billing' : 'Send to QuickBooks',
                    icon: !initValues?.readyForBilling ? <CheckCircle /> : <Send />,
                }}
                additionalHeaderItems={
                    <>
                        <Tooltip title='Download PDF'>
                            {!pdfLoading ? (
                                <Box>
                                    <IconButton onClick={generatePdf} disabled={!initValues || pdfLoading}>
                                        <DownloadForOffline />
                                    </IconButton>
                                </Box>
                            ) : (
                                <CircularProgress />
                            )}
                        </Tooltip>
                        <InvoiceHistoryButton orderReviewId={initValues?.id} size='small' />
                    </>
                }
            />
            <Paper sx={{ display: 'flex', flexGrow: 1, overflow: 'auto', flexDirection: 'column', p: 2, m: 2 }}>
                <Grid container direction='row' spacing={2} alignItems='center'>
                    <Grid item xs={12} display='flex' gap={1} alignItems='center' flexWrap='wrap' justifyContent='center'>
                        {ReferenceItem('Quote', `#${currentQuote?.quoteNumber}`, `/quote/${currentQuote?.id}`)}
                        {ReferenceItem('Job', `#${initValues?.job?.jobNumber ?? order?.job?.jobNumber}`, `/job/${initValues?.jobId ?? jobId}`)}
                        {ReferenceItem(
                            'Order',
                            `#${initValues?.orderNumber ?? order?.orderNumber}`,
                            `/job/${initValues?.jobId ?? jobId}/order/${initValues?.orderId ?? orderId}`
                        )}
                        {ReferenceItem(
                            'Customer',
                            `${initValues?.quote?.customer?.name ?? order?.quote?.customer?.name}`,
                            `/customer/${initValues?.quote?.customerId ?? order?.quote?.customerId}/dashboard`
                        )}
                        <Chip color='secondary' label={initValues?.statusName ?? 'Awaiting Review'} />
                    </Grid>
                    <Grid container spacing={1} alignItems='center'>
                        <Grid item xs={12} sm={8} md={8}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} ml={2}>
                                    <FormInput
                                        label='Project Name'
                                        name='projectName'
                                        value={currentQuote?.projectName ?? ''}
                                        onChange={() => { }}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} ml={2}>
                                    <FormInput
                                        label='Job Name'
                                        name='jobName'
                                        value={currentQuote?.jobName ?? ''}
                                        onChange={() => { }}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} container justifyContent="center">
                            <Grid item xs={12} ml={2}>
                                {gridItem(
                                    <>
                                        <Typography>Project Address:</Typography>
                                        <Typography>{currentQuote?.addressLine1 ?? ''}</Typography>
                                        {currentQuote?.addressLine2 && <Typography>{currentQuote?.addressLine2}</Typography>}
                                        <Typography>{cityStateZip}</Typography>
                                        <Typography>{currentQuote?.country}</Typography>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <DatePicker
                            label='Order Date'
                            value={formOrderDate}
                            onChange={handleOrderDateChange}
                            disabled
                            error={!!baseFormErrors.get('orderDate')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <FormInput value={formPoNumber} onChange={(event) => setFormPoNumber(event.target.value)} label='PO Number' fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2} mt={2}>
                        <FormControl fullWidth disabled={editDisabled}>
                            <FormControlLabel
                                control={<Checkbox disabled={editDisabled} checked={formIsTaxable} onChange={handleIsTaxableChange} />}
                                label='Is Taxable'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <ContactSelect
                            label='Ordered By'
                            customerId={currentQuote?.customerId}
                            selectedContactId={formOrderByContact?.id ?? ''}
                            handleSelectedContactChange={handleOrderedByContactChange}
                            errorMessage={baseFormErrors.get('orderedByContact')}
                            disabled={editDisabled}
                            disableAdd
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <ContactSelect
                            customerId={currentQuote?.customerId}
                            selectedContactId={formContact?.id ?? ''}
                            handleSelectedContactChange={handleContactChange}
                            errorMessage={baseFormErrors.get('contact')}
                            disabled={editDisabled}
                            disableAdd
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInput value={formMemo} onChange={handleMemoChange} label='Memo' fullWidth maxLength={4000} disabled={editDisabled} rows={1} />
                    </Grid>
                </Grid>
                <Typography variant='h2' mt={1}>
                    Order Line Items
                </Typography>
                <Box flexGrow={1} overflow='hidden' minHeight={200}>
                    {order && (
                        <OrderReviewLineItemTable
                            disabled={editDisabled}
                            orderLineItems={formOrderLineItems}
                            order={order}
                            addDispatchReview={handleAddNewDispatchReview}
                            removeDispatchReview={handleRemoveDispatchReview}
                            setOrderLineItems={handleOrderLineItemsChange}
                            removeOrderReviewLineItem={handleRemoveOrderReviewLineItem}
                            addFreightBillingLineItem={handleAddNewFreightBillingLineItem}
                            removeFreightBillingLineItem={handleRemoveFreightBillingLineItem}
                            addOrderReviewLineItem={handleAddOrderReviewLineItemClicked}
                            lineItemErrors={lineItemErrors}
                            dispatchErrors={dispatchErrors}
                            freightErrors={freightErrors}
                        />
                    )}
                </Box>
            </Paper>
            {currentQuote && (
                <AddOrderReviewLineItemDialog
                    open={isAddLineItemDialogOpen}
                    onClose={handleCloseAddLineItemDialog}
                    quote={currentQuote}
                    onAddCustomLineItem={handleAddCustomLineItem}
                    onAddQuoteLineItem={handleAddQuoteLineItem}
                />
            )}
        </Box>
    );
};

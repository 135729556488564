import { Grid, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

export interface NotAuthorizedErrorProps {
    children: any;
    errorCode: any;
}

export const NotAuthorizedError: FunctionComponent<NotAuthorizedErrorProps> = (props) => {
    const { children, errorCode } = props;
    return (
        <Grid container direction='column' alignItems='center'>
            <Grid item>
                <Typography fontWeight={'bold'} fontSize={24}>
                    Oops... something went wrong
                </Typography>
            </Grid>
            <Grid item>
                <Typography>We encountered an issue while processing your request. Please try refreshing the page or logging out and back in. If the problem persists, feel free to contact our support team for assistance.</Typography>
            </Grid>
            <Grid item>
                <Typography>ErrorCode: {errorCode}</Typography>
            </Grid>
            {children}
        </Grid>
    );
};

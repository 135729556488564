import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    IFormProps,
} from '../../../../Components/CoreLib/library';
import { EventModel } from '@bryntum/scheduler';
import { useUpdateOrderLineItemMutation } from '../../../../store/generated/generatedApi';
import { OrderLineItemDto } from '../../../../dtos';

export interface IOrderLineItemEditorFormValues extends IFormProps<EventModel> {
    driverMemo?: string;
    orderLineItemDto?: OrderLineItemDto;
}

export function useOrderLineItemEditorForm(props: IOrderLineItemEditorFormValues) {
    const { save, cancel, initValues, orderLineItemDto } = props;
    const [formDriverMemo, setFormDriverMemo] = useState('');
    const [updateOrderLineItem] = useUpdateOrderLineItemMutation();

    useEffect(() => {
        if (initValues) {
            const event = (initValues as any).data;
            setFormDriverMemo(event?.driverMemo);
        }
    }, [initValues]);

    const isFormDirty = useMemo(() => {
        const data = (initValues as any).data;
        var isDirty = false;
        isDirty = isDirty || formDriverMemo !== data?.driverMemo;
        return isDirty;
    }, [
        initValues,
        formDriverMemo,
    ]);

    const handleSave = useCallback(() => {
        if (initValues) {
            let data = {
                resourceId: initValues.getData('resourceId'),
                driverMemo: formDriverMemo,
            };

            data.resourceId = initValues.getData('resourceId');
            initValues.set(data);

            if (orderLineItemDto) {
                updateOrderLineItem(orderLineItemDto);
            }

            save(initValues);
        }
    }, [
        initValues,
        formDriverMemo,
        updateOrderLineItem,
        orderLineItemDto,
        save,
    ]);

    const handleCancel = useCallback(() => {
        cancel();
    }, [cancel]);

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        setFormDriverMemo,
        formDriverMemo,
    };
}

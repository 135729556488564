import { BryntumGrid, BryntumScheduler } from '@bryntum/scheduler-react';
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { isDateGreaterThanOrEqual } from '../../../../util';
import HaulerDragElement from '../../Components/HaulerDragElement';
import HaulerModel from '../../Stores/Models/HaulerModel';
import { useColumnManager } from './useColumnManager';

interface Props {
    schedulerRef: MutableRefObject<BryntumScheduler>;
    dataList: any[];
    disabled?: boolean;
}

export const HaulerGrid: FC<Props> = (props) => {
    const {
        schedulerRef,
        dataList,
        disabled
    } = props;

    const { haulerGridConfig } = useColumnManager();

    const gridRef = useRef<BryntumGrid>() as MutableRefObject<BryntumGrid>;

    const [store] = useState({
        modelClass: HaulerModel,
        autoLoad: true,
    });

    useEffect(() => {
        const haulerDrag = new HaulerDragElement({
            grid: gridRef.current.instance,
            schedule: schedulerRef.current.instance,
            constrain: true,
            outerElement: gridRef.current.instance.element,
            eventColor: '#A3271F',
            listeners: {
                beforeDragStart({ source }) {
                    if (disabled) {
                        return false;
                    }
                    return isDateGreaterThanOrEqual((source as any).initialConfig.schedule.startDate, new Date());
                },
            },
        });
        return () => {
            haulerDrag.destroy();
        };
    }, [schedulerRef, gridRef, disabled]);

    return <BryntumGrid ref={gridRef} store={store} data={dataList} {...haulerGridConfig} />;
};

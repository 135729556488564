import { TabContext, TabPanel } from '@mui/lab';
import { Box, Grid, Tab, Tabs, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { DateScroller, EquipmentSelect } from '../../Components/CommonInputs';
import { ApiError, AuthenticatedComponent, LoadingIndicator } from '../../Components/CoreLib/library';
import { useGetDispatchActiveDispatchQuery, useGetDispatchPersonalQuery, useGetUserMeQuery } from '../../store/generated/generatedApi';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DispatchCard } from './DispatchCard';
import { format, startOfDay } from 'date-fns';
import { convertYYYYMMDDToDate } from '../../util';

export const DriverMobileView: FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { activeTab } = useParams();
    const [searchParams] = useSearchParams();
    const dateParam = searchParams.get('date');
    const { data: currentUser, isLoading: isLoadingCurrentUser, isFetching: isReFetchingUser, isError: isErrorGettingCurrentUser, refetch: refetchCurrentUser } = useGetUserMeQuery();
    const [selectedTab, setSelectedTab] = useState(activeTab ? activeTab : 'all');
    const [selectedDate, setDate] = useState(dateParam ? convertYYYYMMDDToDate(dateParam) : startOfDay(new Date()));
    const [selectedEquipmentId, setSelectedEquipmentId] = useState('');
    const { data: dispatches } = useGetDispatchPersonalQuery({
        searchText: '', sortKey: 'START_DATE', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false, startDate: format(selectedDate, "yyyy-MM-dd"), equipmentId: selectedEquipmentId ?? undefined
    });
    const { data: activeDispatches } = useGetDispatchActiveDispatchQuery({
        searchText: '', sortKey: 'START_DATE', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false, startDate: format(selectedDate, "yyyy-MM-dd"), equipmentId: selectedEquipmentId ?? undefined
    });

    useEffect(() => {
        if (!activeTab) {
            if (!!activeDispatches) {
                setSelectedTab('active');
            } else {
                setSelectedTab('all');
            }
        } else {
            setSelectedTab(activeTab);
        }
    }, [activeDispatches, activeTab])

    const display = useCallback(() => {
        return (
            <Box>
                <TabContext value={selectedTab}>
                    <TabPanel value={'active'} sx={{ padding: '0 0 75px 0', height: '100%' }}>
                        <Grid container p={2} direction='column' gap={2}>
                            <Grid item container direction='row' alignItems='center' gap={1}>
                                <DateScroller date={selectedDate} updateDate={(updatedDate) => {
                                    setDate(updatedDate);
                                    navigate(`/driver/active?date=${format(updatedDate, "yyyy-MM-dd")}`)
                                }} fullWidth />
                            </Grid>
                            {!!activeDispatches && (
                                activeDispatches?.pageResults.map((dispatch, idx) => {
                                    if(dispatch.isPinned) {
                                        return <Grid item key={dispatch.id}>
                                            <DispatchCard
                                                dispatch={dispatch}
                                                isOpenInitially={true}
                                                isReadonly={false}
                                                isActiveDispatch={dispatch.isActive}
                                                isPinned={dispatch.isPinned}
                                            />
                                        </Grid>
                                    } else {
                                        return <></>
                                    }
                                }))
                            }
                            {!!activeDispatches && (
                                activeDispatches?.pageResults.map((dispatch, idx) => {
                                    if(!dispatch.isPinned) {
                                        return <Grid item key={dispatch.id}>
                                            <DispatchCard
                                                dispatch={dispatch}
                                                isOpenInitially={false}
                                                isReadonly={false}
                                                isActiveDispatch={dispatch.isActive}
                                                isPinned={dispatch.isPinned}
                                            />
                                        </Grid>
                                    } else {
                                        return <></>
                                    }
                                }))
                            }
                        </Grid>
                    </TabPanel>
                    <TabPanel value={'all'} sx={{ padding: '0px' }}>
                        <Box sx={{ paddingBottom: '75px' }}>
                            <Grid container p={2} direction='column' gap={2}>
                                <Grid item container direction='row' alignItems='center' gap={1}>
                                    <DateScroller date={selectedDate} updateDate={(updatedDate) => {
                                        setDate(updatedDate);
                                        navigate(`/driver/all?date=${format(updatedDate, "yyyy-MM-dd")}`)
                                    }} fullWidth />
                                </Grid>
                                {currentUser?.brokerId && (
                                    <AuthenticatedComponent
                                        requiredPermissions={['read:equipment', 'is:hauler']}
                                        children={
                                            <Grid item>
                                                <EquipmentSelect
                                                    selectedEquipmentId={selectedEquipmentId}
                                                    handleSelectedEquipmentChange={(e) => setSelectedEquipmentId(e?.id ?? '')}
                                                    brokerId={currentUser.brokerId}
                                                    disabled={isReFetchingUser}
                                                />
                                            </Grid>
                                        }
                                    />
                                )}
                                <Grid item container direction='column' spacing={1}>
                                    {dispatches?.pageResults.map((dispatch, idx) => {
                                        if(!dispatch.isCompleted) {
                                            return <Grid item key={dispatch.id}>
                                                <DispatchCard
                                                    dispatch={dispatch}
                                                    isOpenInitially={idx === 0}
                                                    isReadonly={isReFetchingUser || !!currentUser?.activeDispatchId}
                                                />
                                            </Grid>
                                        }
                                        return "";
                                    })}
                                </Grid>
                            </Grid>
                        </Box>
                    </TabPanel>
                    <TabPanel value={'completed'} sx={{ padding: '0px', height: '100%' }}>
                        <Box sx={{ paddingBottom: '75px' }}>
                            <Grid container p={2} direction='column' gap={2}>
                                <Grid item container direction='row' alignItems='center' gap={1}>
                                    <DateScroller date={selectedDate} updateDate={(updatedDate) => {
                                        setDate(updatedDate);
                                        navigate(`/driver/all?date=${format(updatedDate, "yyyy-MM-dd")}`)
                                    }} fullWidth />
                                </Grid>
                                <Grid item container direction='column' spacing={1}>
                                    {dispatches?.pageResults.map((dispatch, idx) => {
                                        if(dispatch.isCompleted) {
                                            return <Grid item key={dispatch.id}>
                                                <DispatchCard
                                                    dispatch={dispatch}
                                                    isOpenInitially={idx === 0}
                                                    isReadonly={true}
                                                    isCompleted={true}
                                                />
                                            </Grid>
                                        }
                                        return "";
                                    })}
                                </Grid>
                            </Grid>
                        </Box>
                    </TabPanel>
                    <Box>
                        <Tabs
                            value={selectedTab}
                            onChange={(_, value) => {
                                setSelectedTab(value);
                                navigate(`/driver/${value}`);
                            }}
                            variant='fullWidth'
                            sx={{ borderTop: '1px solid grey', position: 'fixed', bottom: 0, width: '100%' }}
                            TabIndicatorProps={{ sx: { top: 0, zIndex: 5 } }}>
                            <Tab value={'active'} label='ACTIVE DISPATCHES' disabled={!activeDispatches} sx={{ color: theme.palette.primary.main, backgroundColor: selectedTab === 'active' ? '#aaaaaa' : 'white', zIndex: 4 }} />
                            <Tab value={'all'} label='MY DISPATCHES' sx={{ color: theme.palette.primary.main, backgroundColor: selectedTab === 'all' ? '#aaaaaa' : 'white', zIndex: 4 }} />
                            <Tab value={'completed'} label='COMPLETED DISPATCHES' sx={{ color: theme.palette.primary.main, backgroundColor: selectedTab === 'completed' ? '#aaaaaa' : 'white', zIndex: 4 }} />
                        </Tabs>
                    </Box>
                </TabContext>
            </Box>
        );
    }, [activeDispatches, dispatches, selectedTab, currentUser?.activeDispatchId, currentUser?.brokerId, isReFetchingUser, navigate, selectedDate, selectedEquipmentId, theme.palette.primary.main]);

    if (isLoadingCurrentUser) {
        return <LoadingIndicator />;
    }

    if (isErrorGettingCurrentUser || !currentUser) {
        return <ApiError onReloadClick={refetchCurrentUser} />;
    }

    return display();
};

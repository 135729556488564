import {
    Autocomplete,
    CircularProgress,
    Grid,
    IconButton,
    TextField
} from '@mui/material';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { IEntityAutocompleteProps } from './types';
import { AddCircle } from '@mui/icons-material';
import { AuthenticatedComponent } from '../../CoreLib/library';
import { ConfirmationDialogWithInput } from '../../Core/ConfirmDialog/ConfirmationDialogWithInput';

export const IEntityAutocomplete: FunctionComponent<IEntityAutocompleteProps<any>> = React.memo((props) => {
    const { value, onChange, disabled, options, isLoading, getOptionLabel, error, validate, quickAddProps, defaultOptions } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [isQuickAddDialogOpen, setIsQuickAddDialogOpen] = useState(false);
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        if (isChanged && validate) {
            validate();
            setIsChanged(false);
        }
    }, [isChanged, validate, value]);

    const getLabel = useCallback((option: any) => {
        return `${getOptionLabel !== undefined ? getOptionLabel(option) : ""}${option.isActive ? '' : ' (Inactive)'}`;
    }, [getOptionLabel]);

    const listOptions = useMemo(() => {
        let o = [] as any[];
        if (defaultOptions) {
            o = defaultOptions;
        }
        if (options) {
            o = o.concat(options);
        }
        return o;
    }, [defaultOptions, options]);

    return (
        <Grid item container direction='row' xs={12} alignItems={'center'}>
            <Grid item xs={quickAddProps ? 10 : 12}>
                <Autocomplete
                    value={value ?? null}
                    onChange={(event, value) => { onChange?.(event, value); setIsChanged(true); }}
                    open={isOpen}
                    disabled={disabled}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    options={listOptions}
                    loading={isLoading}
                    onBlur={validate}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    getOptionLabel={getLabel}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={error}
                            onBlur={validate}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {getLabel(option)}
                            </li>
                        );
                    }}
                />
            </Grid>
            {quickAddProps &&
                <AuthenticatedComponent
                    requiredPermissions={quickAddProps.requiredPermissions}
                    children={
                        <Grid item xs={2}>
                            <IconButton color='primary' size='large'>
                                <AddCircle fontSize='inherit' onClick={() => setIsQuickAddDialogOpen(true)} />
                            </IconButton>
                            <ConfirmationDialogWithInput
                                open={isQuickAddDialogOpen}
                                onClose={() => {
                                    setIsQuickAddDialogOpen(false);
                                }}
                                formLabel={quickAddProps.objectName}
                                title={`New ${quickAddProps.objectName}`}
                                titleIcon={quickAddProps.dialogIcon ?? <AddCircle />}
                                cancelText={"Cancel"}
                                confirmText={"Save"}
                                save={quickAddProps.handleSave}
                            />
                        </Grid>
                    }
                />}
        </Grid>

    );
});

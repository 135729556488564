import { Grid, Link, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ConfirmDialog } from "../../Components/Core/ConfirmDialog";
import { AddCircle, Error, ListAlt } from "@mui/icons-material";
import { DatePicker } from "../../Components/CommonInputs";
import { AuthenticatedComponent, StandardCardWithHeader } from "../../Components/CoreLib/library";
import { JobDto, OrderDto, QuoteDto } from "../../dtos";
import { useNavigate } from "react-router-dom";
import { QuoteLineItemsOrderView } from "./QuoteLineItemsOrderView";
import _ from "lodash";
import { emptyGuid } from "../../util";

export interface IAddOrderDateModal {
    onSave: (values: OrderDto) => void;
    onClose: () => void;
    open: boolean;
    job: JobDto;
}

export const AddOrderDateModal: FC<IAddOrderDateModal> = (props) => {
    const { onSave, onClose, open, job } = props;
    const navigate = useNavigate();
    const [formOrderDate, setFormOrderDate] = useState<Date | null | undefined>(new Date());
    const [formQuote, setFormQuote] = useState<QuoteDto | null | undefined>(job.currentQuote ?? null);

    const getEarliestQuoteDate = useCallback(() => {
        const earliestQuote = _.orderBy(job?.quotes, (x) => new Date(x.effectiveOn!), 'asc')[0];
        if (earliestQuote?.effectiveOn) {
            const date = new Date(earliestQuote.effectiveOn);
            date.setHours(0, 0, 0, 0);
            return date;
        }
        return undefined;
    }, [job?.quotes]);

    const [earliestQuoteDate, setEarliestQuoteDate] = useState<Date | undefined>(getEarliestQuoteDate());

    const isExpiredQuote = useCallback((quote: QuoteDto) => {
        const expirationDate = new Date(quote.expirationDate!);
        return expirationDate < new Date();
    }, []);

    useEffect(() => {
        setEarliestQuoteDate(getEarliestQuoteDate());
    }, [getEarliestQuoteDate, setEarliestQuoteDate])

    const handleOnConfirm = useCallback(() => {
        onSave({
            id: emptyGuid,
            clientId: emptyGuid,
            jobId: job.id,
            quoteId: formQuote?.id ?? emptyGuid,
            orderNumber: undefined,
            orderDate: formOrderDate ?? new Date(),
            isActive: true,
            createdOn: new Date(),
            orderLineItems: [],
            memo: job.currentQuote?.memo ?? '',
            poNumber: '',
            contact: job.currentQuote?.contact ?? undefined,
            contactId: job.currentQuote?.contactId ?? undefined,
            isTaxable: job.currentQuote?.isTaxable ?? false,
        });
    }, [formOrderDate, formQuote?.id, job.currentQuote, job.id, onSave]);

    const getCurrentQuoteForOrderDate = useCallback(
        (orderDate: Date) => {
            const quotes = job?.quotes?.filter((x) => {
                var checkDate = new Date(x.effectiveOn!);
                checkDate.setHours(0, 0, 0, 0);
                return checkDate <= orderDate
            }) ?? [];
            var orderedQuotes = _.orderBy(quotes, (x) => new Date(x.effectiveOn!), 'desc');
            if (orderedQuotes.length > 0) {
                return orderedQuotes[0];
            }
            return undefined;
        },
        [job?.quotes]
    );

    const handleOrderDateChange = useCallback(
        (value: Date | null | undefined) => {
            setFormOrderDate(value);
            if (value) {
                setFormQuote(getCurrentQuoteForOrderDate(value));
            } else {
                setFormQuote(undefined);
            }
        },
        [getCurrentQuoteForOrderDate, setFormOrderDate, setFormQuote]
    );

    const disableConfirmMemo = useMemo(() => {
        if (formQuote === null || formQuote === undefined) {
            return true;
        } else {
            return false;
        }
    }, [formQuote]);

    return (
        <ConfirmDialog
            open={open}
            onClose={() => {
                onClose();
            }}
            disableConfirm={disableConfirmMemo}
            maxWidth="lg"
            content={
                <Grid item container direction='column' spacing={2}>
                    <Grid item alignSelf={'center'}>
                        <Typography>Please enter the date for this Order. This will link the new Order to a Quote Price List.</Typography>
                    </Grid>
                    <Grid item container direction='row' alignSelf={'center'} xs={4} justifyContent={'center'}>
                        <Grid item xs={4}>
                            <DatePicker
                                value={formOrderDate ?? null}
                                onChange={(date) => {
                                    handleOrderDateChange(date);
                                }}
                                minDate={earliestQuoteDate}
                                required
                                label="Order Date"
                            />
                        </Grid>
                    </Grid>

                    {formQuote && <Grid item xs={12}>
                        <StandardCardWithHeader headerTitle='Quote Price List' headerIcon={<ListAlt />}>
                            <Grid container direction='column' pb={2}>
                                <Grid item container direction='row' px={2} justifyContent='space-between' alignItems='center'>
                                    <Grid item>
                                        <Grid item container direction='column'>
                                            <AuthenticatedComponent
                                                requiredPermissions={['read:quote']}
                                                children={
                                                    <Grid item>
                                                        {formQuote ? <Link
                                                            variant='body2'
                                                            href={`/quote/${formQuote?.id}`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                navigate(`/quote/${formQuote?.id}`);
                                                            }}
                                                        >
                                                            {job?.jobName ? job?.jobName + ' - ' + job?.customerName + ' (Quote #' + formQuote?.quoteNumber + ')' : job?.customerName + ' (Quote #' + formQuote?.quoteNumber + ')'}
                                                        </Link> : <Typography color='error'>Error: No valid quote for that order date!</Typography>}
                                                    </Grid>
                                                }
                                            />
                                            <Grid item>
                                                {formQuote && isExpiredQuote(formQuote) && (
                                                    <Grid item container spacing={1}>
                                                        <Grid item>
                                                            <Error color='error' />
                                                        </Grid>
                                                        <Grid item>
                                                            <strong>Quote Expired: {new Date(formQuote.expirationDate!).toLocaleDateString()}</strong>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    {formQuote && (
                                        <QuoteLineItemsOrderView quote={formQuote} />
                                    )}
                                </Grid>
                            </Grid>
                        </StandardCardWithHeader>
                    </Grid>}
                </Grid>
            }
            title={'Add New Order'}
            titleIcon={<AddCircle />}
            onConfirm={handleOnConfirm}
            confirmText='Confirm'
            requireConfirmation={false}

        />
    );
}
import { EventModel, Popup } from '@bryntum/scheduler';
import { BryntumGrid, BryntumScheduler } from '@bryntum/scheduler-react';
import { FC, MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { OrderLineItemDto } from '../../../../dtos';
import { isDateGreaterThanOrEqual } from '../../../../util';
import OrderDragElement from '../../Components/OrderDragElement';
import OrderLineItemModel from '../../Stores/Models/OrderLineItemModel';
import { getOrderLineItemTooltipData } from '../OrderLineItemHelper';
import { useColumnManager } from './useColumnManager';
import { userHasPermissions, UserPermissionContext } from '../../../../Components/CoreLib/library';
import { OrderLineItemEditor } from '../OrderDispatching/OrderLineItemEditor';

interface Props {
    date: Date;
    schedulerRef: MutableRefObject<BryntumScheduler>;
    dataList: any[];
    disabled?: boolean;
}

export const OrderLineItemGrid: FC<Props> = (props) => {
    const {
        date: currentDate,
        schedulerRef,
        dataList,
        disabled
    } = props;

    const { orderLineItemGridConfig } = useColumnManager();
    const [date] = useState<Date>(currentDate);

    const { permissions } = useContext(UserPermissionContext);
    const isUserAllowedToCreateAndEdit = userHasPermissions(['create:lineItem', 'edit:lineItem'], permissions);
    const [selectedLineItem, setSelectedLineItem] = useState<EventModel | undefined>();
    const [selectedOrderLineItemDto, setSelectedOrderLineItemDto] = useState<OrderLineItemDto | undefined>();
    const [isEditLineItemOpen, setIsEditLineItemOpen] = useState(false);

    const isNotOutOfDateHelper = useCallback(
        (date: Date) => isDateGreaterThanOrEqual(date, new Date()),
        []);

    const [store] = useState({
        modelClass: OrderLineItemModel,
        autoLoad: true,
    });

    const gridRef = useRef<BryntumGrid>() as MutableRefObject<BryntumGrid>;

    const handleCellClick = useCallback(({ grid, record, column, cellElement, target, event }) => {

        if (record.isSpecialRow) {
            return;
        }

        const orderLineItem = record.data as OrderLineItemDto;

        new Popup({
            forElement: event.target as any,
            cls: 'b-demo-unavailable',
            header: { title: 'Order Line Item Information', cls: 'driver' },
            html: getOrderLineItemTooltipData(orderLineItem),
            width: '70em',
            closeAction: 'destroy',
            scrollAction: 'realign',
            closable: true,
            tools: {
                edit: {
                    type: 'tool',
                    disabled: !isNotOutOfDateHelper(date) || !isUserAllowedToCreateAndEdit,
                    cls: 'b-icon b-icon-edit custom-edit-icon',
                    onClick: () => {
                        setSelectedLineItem(record);
                        setSelectedOrderLineItemDto(orderLineItem);
                        setIsEditLineItemOpen(true);
                    },
                },
            },
        });

    }, [date, isNotOutOfDateHelper, isUserAllowedToCreateAndEdit]);

    useEffect(() => {
        if (gridRef.current) {
            const grid = gridRef.current.instance;
            grid.on('cellClick', handleCellClick);
        }
    }, [handleCellClick, gridRef]);

    useEffect(() => {
        const drag = new OrderDragElement({
            grid: gridRef.current.instance,
            schedule: schedulerRef.current.instance,
            constrain: true,
            outerElement: gridRef.current.instance.element,
            eventColor: '#A3271F',
            listeners: {
                beforeDragStart({ source }) {
                    if (disabled) {
                        return false;
                    }
                    return isDateGreaterThanOrEqual((source as any).initialConfig.schedule.startDate, new Date());
                },
            },
        });
        return () => {
            drag.destroy();
        };
    }, [schedulerRef, gridRef, disabled]);

    return (
        <>
            <BryntumGrid ref={gridRef} store={store} data={dataList} {...orderLineItemGridConfig} />
            {isEditLineItemOpen && (
                <OrderLineItemEditor
                    initValues={selectedLineItem}
                    orderLineItemDto={selectedOrderLineItemDto}
                    save={() => {
                        setIsEditLineItemOpen(false);
                        setSelectedLineItem(undefined);
                    }}
                    cancel={() => {
                        setIsEditLineItemOpen(false);
                        setSelectedLineItem(undefined);
                    }}
                    open={true}
                    disabled={false}
                />
            )}
        </>
    );
};

import { EventModel } from '@bryntum/scheduler';
import { BryntumScheduler, BryntumSchedulerProps, BryntumSplitter } from '@bryntum/scheduler-react';
import { add, endOfDay, startOfDay } from 'date-fns';
import { FC, MutableRefObject, useCallback, useEffect, useState } from 'react';
import { WebhookConnector } from '../../../../Components/Webhook';
import { DispatchDto } from '../../../../dtos';
import {
    useArchiveDispatchDeleteFromDatabaseByIdMutation,
    useLazyGetDispatchHaulerQuery,
    useUpdateDispatchMutation,
} from '../../../../store/generated/generatedApi';
import { areDateTimesEqual, emptyGuid, formatDate } from '../../../../util';
import { EventEditor } from '../../Components';
import DispatchEventModel from '../../Stores/DispatchEvent/DispatchEventModel';
import HaulerDispatchStore from '../../Stores/DispatchEvent/DispatchEventStore';
import { OrderLineItemGrid } from '../Grids/OrderLineItemGrid';
import { HaulerManagementDispatchingViewScheduler } from './HaulerManagementDispatchingViewScheduler';

export interface IHaulerManagementDispatchingViewProps {
    date: Date;
    isEditEventOpen: boolean;
    schedulerConfig: BryntumSchedulerProps;
    schedulerRef: MutableRefObject<BryntumScheduler>;
    selectedEvent?: EventModel;
    setEditEventOpen: (isOpen: boolean) => void;
    setSelectedEvent: (event?: EventModel) => void;
    webhookConnector: WebhookConnector;
    disabled?: boolean;
}

export const HaulerManagementDispatchingView: FC<IHaulerManagementDispatchingViewProps> = (props) => {
    const {
        date: currentDate,
        isEditEventOpen,
        schedulerConfig,
        schedulerRef,
        selectedEvent,
        setEditEventOpen,
        setSelectedEvent,
        webhookConnector,
        disabled = false,
    } = props;
    const [updateDispatch] = useUpdateDispatchMutation();
    const [deleteDispatch] = useArchiveDispatchDeleteFromDatabaseByIdMutation();
    const [getData, { data }] = useLazyGetDispatchHaulerQuery();
    const [date, setDate] = useState<Date>(currentDate);

    const [crudManager] = useState({
        eventStore: {
            // @ts-ignore
            storeClass: HaulerDispatchStore,
            autoLoad: true,
            syncDataOnLoad: true,
        },
        assignmentStore: {
            autoLoad: true,
            syncDataOnLoad: true,
        },
        resourceStore: {
            autoLoad: true,
            syncDataOnLoad: true,
        },
    } as any);

    const loadData = useCallback(
        (date: Date) => {
            Promise.all([getData({ startDate: formatDate(date, 'yyyy-MM-dd') })]);
        },
        [getData]
    );
    useEffect(() => {
        loadData(currentDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (date !== currentDate) {
            loadData(currentDate);
            setDate(currentDate);
        }
    }, [currentDate, date, loadData]);

    useEffect(() => {
        const startDate = startOfDay(date);
        const endDate = endOfDay(startDate);
        schedulerRef?.current?.instance?.setTimeSpan(startDate, endDate);

        // Scroll the timeline to 6am
        const scrollToTime = new Date(startDate);
        scrollToTime.setHours(6);
        schedulerRef?.current?.instance.scrollToDate(scrollToTime, { block: 'start' });
    }, [data, date, schedulerRef, schedulerRef?.current?.instance]);

    useEffect(() => {
        const { events } = webhookConnector;
        if (!events) {
            return;
        }
        events((updatedDate, id) => {
            if (updatedDate === formatDate(date, 'yyyy-MM-dd')) {
                loadData(date);
                if (id === '' || id === selectedEvent?.id) {
                    setEditEventOpen(false);
                }
            }
        });
    }, [webhookConnector, date, loadData, selectedEvent?.id, setEditEventOpen]);

    const createDispatchDto = useCallback(
        (data: any) => {
            // if start date shifts to the previous date, set it to midnight of the current date.
            if (data.startDate < startOfDay(currentDate)) {
                data.startDate = startOfDay(currentDate);
            }

            if (data.endDate > endOfDay(currentDate)) {
                data.endDate = endOfDay(currentDate);
            }

            if (data.endDate < data.startDate) {
                data.endDate = add(data.startDate, { hours: 1 });
            }

            // scroll to the start date of the new event
            schedulerRef.current.instance.scrollToDate(data.startDate);

            const dispatch = {
                id: emptyGuid,
                isActive: true,
                createdOn: new Date(),
                orderLineItemId: data.orderLineItemId,
                driverId: data.driverId,
                brokerId: data.brokerId,
                equipmentId: data.equipmentId ?? data.driver?.equipmentId ?? undefined,
                startDate: data.startDate,
                endDate: data.endDate,
                onsiteTime: data.onsiteTime,
                yardTime: data.yardTime,
                clientId: emptyGuid,
                released: data.released,
                haulToAddressLine1: data.haulToAddressLine1,
                haulToAddressLine2: data.haulToAddressLine2,
                haulToCity: data.haulToCity,
                haulToCountry: data.haulToCountry,
                haulToState: data.haulToState,
                haulToZipCode: data.haulToZipCode,
                haulFromAddressLine1: data.haulFromAddressLine1,
                haulFromAddressLine2: data.haulFromAddressLine2,
                haulFromCity: data.haulFromCity,
                haulFromCountry: data.haulFromCountry,
                haulFromState: data.haulFromState,
                haulFromZipCode: data.haulFromZipCode,
                haulToSiteName: data.haulToSiteName,
                haulToRegion: data.haulToRegion,
                haulToRegionId: data.haulToRegionId,
                haulFromSiteName: data.haulFromSiteName,
                haulFromRegion: data.haulFromRegion,
                haulFromRegionId: data.haulFromRegionId,
                driverMemo: data.driverMemo ?? data.orderLineItem?.driverMemo,
                isHaulFromAddressLocked: data.isHaulFromAddressLocked,
                isHaulToAddressLocked: data.isHaulToAddressLocked,
            } as DispatchDto;

            if (data.id !== emptyGuid) {
                dispatch.id = data.id;
                dispatch.isActive = data.isActive;
                dispatch.createdOn = data.createdOn;
                dispatch.clientId = data.clientId;
            }

            return dispatch;
        },
        [currentDate, schedulerRef]
    );

    const handleSave = useCallback(
        (data: any, originalData: any, record: DispatchEventModel) => {
            if (
                data.id === emptyGuid ||
                !areDateTimesEqual(data.startDate, originalData.startDate) ||
                !areDateTimesEqual(data.endDate, originalData.endDate) ||
                !areDateTimesEqual(data.onsiteTime, originalData.onsiteTime) ||
                !areDateTimesEqual(data.yardTime, originalData.yardTime) ||
                data.orderLineItemId !== originalData.orderLineItemId ||
                (data.driverId ?? undefined) !== (originalData.driverId ?? undefined) ||
                (data.brokerId ?? undefined) !== (originalData.brokerId ?? undefined) ||
                (data.equipmentId ?? undefined) !== (originalData.equipmentId ?? undefined) ||
                data.released !== originalData.released ||
                data.haulToAddressLine1 !== originalData.haulToAddressLine1 ||
                data.haulToAddressLine2 !== originalData.haulToAddressLine2 ||
                data.haulToCity !== originalData.haulToCity ||
                data.haulToCountry !== originalData.haulToCountry ||
                data.haulToState !== originalData.haulToState ||
                data.haulToZipCode !== originalData.haulToZipCode ||
                data.haulFromAddressLine1 !== originalData.haulFromAddressLine1 ||
                data.haulFromAddressLine2 !== originalData.haulFromAddressLine2 ||
                data.haulFromCity !== originalData.haulFromCity ||
                data.haulFromCountry !== originalData.haulFromCountry ||
                data.haulFromState !== originalData.haulFromState ||
                data.haulFromZipCode !== originalData.haulFromZipCode ||
                data.haulToSiteName !== originalData.haulToSiteName ||
                data.haulToRegionId !== originalData.haulToRegionId ||
                data.haulFromSiteName !== originalData.haulFromSiteName ||
                data.haulFromRegionId !== originalData.haulFromRegionId ||
                data.driverMemo !== originalData.driverMemo ||
                data.isHaulFromAddressLocked !== originalData.isHaulFromAddressLocked ||
                data.isHaulToAddressLocked !== originalData.isHaulToAddressLocked
            ) {
                const dto = createDispatchDto(data);
                if (dto.brokerId || dto.driverId) {
                    schedulerRef?.current?.instance?.eventStore?.remove(record);
                    updateDispatch(dto);
                }
            }
        },
        [createDispatchDto, updateDispatch, schedulerRef]
    );

    const onEventStoreUpdate = useCallback(
        ({ source, record, changes }: { source: any; record: DispatchEventModel; changes: any }): void => {
            const newData = (record as any).data;
            const originalData = (record as any).originalData;
            if (changes.updatedOn) {
                return;
            }
            var resource = data?.resources.find((x) => x.id === record.resourceId);
            if (!resource) {
                return;
            }
            if (resource.haulerType === 'Drivers') {
                newData.driverId = record.resourceId;
                newData.brokerId = undefined;
                newData.equipmentId = newData?.equipmentId ?? newData?.driver?.equipmentId;
                newData.equipment = newData?.equipment ?? newData?.driver?.equipment;
            } else {
                newData.brokerId = record.resourceId;
                newData.driverId = undefined;
                newData.equipmentId = undefined;
                newData.equipment = undefined;
            }
            handleSave(newData, originalData, record);
        },
        [data, handleSave]
    );

    return (
        <>
            {isEditEventOpen && (
                <EventEditor
                    initValues={selectedEvent}
                    isHaulerView
                    handleDelete={() => {
                        if (selectedEvent) {
                            const eventStore = schedulerRef.current.instance.eventStore;
                            eventStore.remove(selectedEvent);
                            deleteDispatch({ id: selectedEvent.getData('id') });
                            setEditEventOpen(false);
                            setSelectedEvent(undefined);
                        }
                    }}
                    open={isEditEventOpen}
                    cancel={() => {
                        setEditEventOpen(false);
                        setSelectedEvent(undefined);
                    }}
                    save={() => {
                        setEditEventOpen(false);
                        setSelectedEvent(undefined);
                    }}
                    disabled={disabled}
                />
            )}
            <div id='main'>
                <HaulerManagementDispatchingViewScheduler
                    schedulerRef={schedulerRef}
                    config={{ ...schedulerConfig }}
                    resources={data?.resources ?? []}
                    events={data?.events ?? []}
                    crudManager={crudManager}
                    onEventStoreUpdate={onEventStoreUpdate}
                />
                <BryntumSplitter />
                <div id='unplannedContainer'>
                    <OrderLineItemGrid schedulerRef={schedulerRef} dataList={data?.orderLineItems ?? []} disabled={disabled} date={date} />
                </div>
            </div>
        </>
    );
};

import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Button, Divider, Grid, InputLabel, Menu, MenuItem, Paper, Toolbar, Typography } from '@mui/material';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import logo from '../../../src/logoHorizontal.png';
import { NavigationDrawerContainer } from '../NavigationDrawerContainer.tsx/NavigationDrawerContainer';
import { AuthenticatedComponent, UserAvatar, LoadingIndicator, PermissionToggler, UserPermissionContext } from '../CoreLib/library';
import { useLazyGetUserAuth0UserIdByIdQuery, useLazyGetUserPermissionsQuery, useUpdateUserMutation } from '../../store/generated/generatedApi';
import { NotAuthorizedError } from '../Core/NotAuthorizedError';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { UserDto } from '../../dtos';
import _ from 'lodash';

export interface IShellProps {
    isProtected?: boolean;
}

const Shell: FC<IShellProps> = (props) => {
    const { isProtected } = props;
    const navigate = useNavigate();
    const { user, isAuthenticated, loginWithRedirect, logout, isLoading: isAuthLoading } = useAuth0();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const profileMenuOpen = Boolean(anchorEl);
    const [getUserData, { data: userData, isLoading: isLoadingUser, error: userError }] = useLazyGetUserAuth0UserIdByIdQuery();
    const [updateUser] = useUpdateUserMutation();
    const { permissions } = useContext(UserPermissionContext);
    // State to store the current time
    const [currentTime, setCurrentTime] = useState('');

    useEffect(() => {
        // Function to update the current time
        const updateTime = () => {
            setCurrentTime(new Date().toLocaleTimeString('en-US', { timeZone: 'America/New_York' }));
        };

        // Set an interval to update the current time every second
        const intervalId = setInterval(updateTime, 1000);

        // Clear the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const [defaultHomepage, setDefaultHomepage] = useState(userData?.defaultHomepage ? userData.defaultHomepage : '');

    useEffect(() => {
        setDefaultHomepage(userData?.defaultHomepage ? userData.defaultHomepage : '');
    }, [userData?.defaultHomepage]);

    useEffect(() => {
        if (isAuthenticated) {
            if (user?.sub) {
                getUserData({
                    id: user?.sub,
                });
            }
        }
    }, [user, getUserData, isAuthenticated, navigate]);

    const handleLogout = useCallback(() => {
        setAnchorEl(null);
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    }, [logout]);

    const handleProfileMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleProfileMenuClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleUpdateUserInfo = useCallback((values: UserDto) => {
        updateUser(values);
    }, [updateUser]);

    const handleDefaultHomepageChange = useCallback(
        (event: SelectChangeEvent) => {
            const updatedUser = _.cloneDeep(userData) as UserDto;
            updatedUser.defaultHomepage = event.target.value as string;
            handleUpdateUserInfo(updatedUser);
            setDefaultHomepage(event.target.value as string);
        },
        [handleUpdateUserInfo, userData]
    );

    const defaultHomepageMenuItems = useMemo(() => {
        var menuItems: string[] = [];
        if (!!permissions.find((x) => x === 'read:quote')) {
            menuItems.push('Quotes');
        }
        if (!!permissions.find((x) => x === 'read:job')) {
            menuItems.push('Jobs');
        }
        if (!!permissions.find((x) => x === 'read:dispatch')) {
            menuItems.push('Dispatch');
        }
        if (!!permissions.find((x) => x === 'read:review')) {
            menuItems.push('Order Review');
        }
        return menuItems;
    }, [permissions]);

    if (isAuthLoading || isLoadingUser) {
        return <LoadingIndicator />;
    }

    if (isProtected) {
        if (!isAuthenticated) {
            localStorage.setItem('redirectUrl', window.location.pathname + window.location.search);
            loginWithRedirect();
            return <LoadingIndicator />;
        } else if (userError) {
            const path = localStorage.getItem('redirectUrl');
            if (path === window.location.pathname + window.location.search) {
                localStorage.removeItem('redirectUrl');
            }
            return (
                <NotAuthorizedError
                    errorCode={(userError as any)?.status ?? (userError as any)?.data?.status}
                    children={
                        <Grid item>
                            <Button
                                variant='contained'
                                color='primary'
                                sx={{ mr: 2 }}
                                onClick={() => {
                                    window.location.reload();
                                }}>
                                Refresh
                            </Button>
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={() => {
                                    logout({
                                        logoutParams: {
                                            returnTo: window.location.origin,
                                        },
                                    });
                                }}>
                                Logout
                            </Button>
                        </Grid>
                    }
                />
            );
        }
    }

    return (
        <>
            <NavigationDrawerContainer>
                <AppBar position='sticky' elevation={0} sx={{ padding: '0px' }}>
                    <Toolbar variant='dense'>
                        <Grid item sx={{ mt: 0.75, mx: 0, px: 0 }}>
                            <a href='/'>
                                <img src={logo} alt='TRIAX Logo' height={'56px'} />
                            </a>
                        </Grid>
                        {isAuthenticated && (
                            <Grid container direction='row' justifyContent='end' alignItems='span' spacing={1}>
                                <Grid item>
                                    <Paper sx={{ height: '100%', px: 1, textAlign: 'center' }}>Current Time EST <br /> {currentTime}</Paper>
                                </Grid>
                                <Grid item style={{ cursor: 'pointer' }} onClick={handleProfileMenuOpen}>
                                    <UserAvatar name={user?.name} />
                                </Grid>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={profileMenuOpen}
                                    onClose={handleProfileMenuClose}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    PaperProps={{
                                        sx: {
                                            mt: 1.5,
                                            border: 'solid 1px #AAAAAA',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            minWidth: '240px',
                                        },
                                    }}>
                                    <Grid container direction='column' alignItems='center'>
                                        <Grid item container direction='row' justifyContent='center'>
                                            <UserAvatar name={user?.name} />
                                        </Grid>
                                        <Grid item container direction='row' justifyContent='center'>
                                            <Typography sx={{ fontSize: '18px', marginTop: '12px' }}>
                                                {userData ? userData?.displayName : user?.email}
                                            </Typography>
                                        </Grid>
                                        <Grid item container direction='row' justifyContent='center'>
                                            <Typography sx={{ fontSize: '14px', marginBottom: '12px', paddingLeft: '8px', paddingRight: '8px' }} noWrap>
                                                {user?.email}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <AuthenticatedComponent
                                        requiredPermissions={['read:quote', 'read:review', 'read:job', 'read:dispatch']}
                                        logic='or'
                                        children={<Divider sx={{ marginBottom: '12px' }} />}
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['read:quote', 'read:review', 'read:job', 'read:dispatch']}
                                        logic='or'
                                        children={
                                            <Grid item sx={{ marginBottom: '12px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel>Default Homepage</InputLabel>
                                                    <Select value={defaultHomepage} fullWidth label='Default Homepage' onChange={handleDefaultHomepageChange}>
                                                        {defaultHomepageMenuItems.map((menuItem) => (
                                                            <MenuItem value={menuItem} key={menuItem}>
                                                                {menuItem}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        }
                                    />

                                    <Divider sx={{ marginBottom: '12px' }} />
                                    <MenuItem onClick={handleLogout} sx={{ fontSize: '14px' }}>
                                        Sign Out
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        )}
                    </Toolbar>
                </AppBar>
                <div style={{ height: 'calc(100vh - 72px)' }}>{isAuthLoading || !isAuthenticated ? <LoadingIndicator /> : <Outlet />}</div>
            </NavigationDrawerContainer>
            <AuthenticatedComponent
                requiredPermissions={['read:permissionToggle']}
                children={<PermissionToggler getPermissionsQuery={useLazyGetUserPermissionsQuery} />}
            />
        </>
    );
};

export default Shell;

import { CrudManagerConfig, EventModel, Popup } from '@bryntum/scheduler';
import { BryntumScheduler, BryntumSchedulerProps, BryntumSplitter } from '@bryntum/scheduler-react';
import { add, endOfDay, startOfDay } from 'date-fns';
import { FC, MutableRefObject, useCallback, useEffect, useState, useContext } from 'react';
import { WebhookConnector } from '../../../../Components/Webhook';
import { DispatchDto, OrderLineItemDto } from '../../../../dtos';
import {
    useArchiveDispatchDeleteFromDatabaseByIdMutation,
    useLazyGetDispatchOrderManagementQuery,
    useUpdateDispatchMutation,
} from '../../../../store/generated/generatedApi';
import { areDateTimesEqual, emptyGuid, formatDate, isDateGreaterThanOrEqual } from '../../../../util';
import { EventEditor } from '../../Components';
import { OrderLineItemEditor } from '../../Views/OrderDispatching/OrderLineItemEditor';
import DispatchEventModel from '../../Stores/DispatchEvent/DispatchEventModel';
import DispatchEventStore from '../../Stores/DispatchEvent/DispatchEventStore';
import { HaulerGrid } from '../Grids/HaulerGrid';
import { getOrderLineItemTooltipData } from '../OrderLineItemHelper';
import { OrderDispatchingViewScheduler } from './OrderDispatchingViewScheduler';
import { userHasPermissions, UserPermissionContext } from '../../../../Components/CoreLib/library';

export interface IOrderDispatchingViewProps {
    date: Date;
    isEditEventOpen: boolean;
    schedulerConfig: BryntumSchedulerProps;
    schedulerRef: MutableRefObject<BryntumScheduler>;
    selectedEvent?: EventModel;
    setEditEventOpen: (isOpen: boolean) => void;
    setSelectedEvent: (event?: EventModel) => void;
    webhookConnector: WebhookConnector;
    disabled?: boolean;
}

export const OrderDispatchingView: FC<IOrderDispatchingViewProps> = (props) => {
    const {
        date: currentDate,
        isEditEventOpen,
        schedulerConfig,
        schedulerRef,
        selectedEvent,
        setEditEventOpen,
        setSelectedEvent,
        webhookConnector,
        disabled = false,
    } = props;
    const [updateDispatch] = useUpdateDispatchMutation();
    const [deleteDispatch] = useArchiveDispatchDeleteFromDatabaseByIdMutation();
    const [getData, { data }] = useLazyGetDispatchOrderManagementQuery();
    const [date, setDate] = useState<Date>(currentDate);

    const { permissions } = useContext(UserPermissionContext);
    const isUserAllowedToCreateAndEdit = userHasPermissions(['create:lineItem', 'edit:lineItem'], permissions);
    const [selectedLineItem, setSelectedLineItem] = useState<EventModel | undefined>();
    const [selectedOrderLineItemDto, setSelectedOrderLineItemDto] = useState<OrderLineItemDto | undefined>();
    const [isEditLineItemOpen, setIsEditLineItemOpen] = useState(false);

    const isNotOutOfDateHelper = useCallback(
        (date: Date) => isDateGreaterThanOrEqual(date, new Date()),
        []);

    const [crudManager] = useState<CrudManagerConfig>({
        eventStore: {
            // @ts-ignore
            storeClass: DispatchEventStore,
            autoLoad: true,
            syncDataOnLoad: true,
            validateResponse: true,
        },
        assignmentStore: {
            autoLoad: true,
            syncDataOnLoad: true,
            validateResponse: true,
        },
        resourceStore: {
            autoLoad: true,
            syncDataOnLoad: true,
            validateResponse: true,
        },
    } as any);

    const handleCellClick = useCallback(({ grid, record, column, cellElement, target, event }) => {

        if (record.isSpecialRow) {
            return;
        }

        const orderLineItem = record.data as OrderLineItemDto;

        new Popup({
            forElement: event.target as any,
            cls: 'b-demo-unavailable',
            header: { title: 'Order Line Item Information', cls: 'driver' },
            html: getOrderLineItemTooltipData(orderLineItem),
            width: '70em',
            closeAction: 'destroy',
            scrollAction: 'realign',
            closable: true,
            tools: {
                edit: {
                    type: 'tool',
                    disabled: !isNotOutOfDateHelper(date) || !isUserAllowedToCreateAndEdit,
                    cls: 'b-icon b-icon-edit custom-edit-icon',
                    onClick: () => {
                        setSelectedLineItem(record);
                        setSelectedOrderLineItemDto(orderLineItem);
                        setIsEditLineItemOpen(true);
                    },
                },
            },
        });

    }, [date, isNotOutOfDateHelper, isUserAllowedToCreateAndEdit]);

    useEffect(() => {
        if (schedulerRef.current) {
            const scheduler = schedulerRef.current.instance;
            scheduler.on('cellClick', handleCellClick);
        }
    }, [handleCellClick, schedulerRef]);

    const loadData = useCallback(
        (date: Date) => {
            Promise.all([getData({ startDate: formatDate(date, 'yyyy-MM-dd') })]);
        },
        [getData]
    );

    useEffect(() => {
        loadData(currentDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (date !== currentDate) {
            loadData(currentDate);
            setDate(currentDate);
        }
    }, [currentDate, date, loadData]);

    useEffect(() => {
        const startDate = startOfDay(date);
        const endDate = endOfDay(startDate);
        schedulerRef?.current?.instance?.setTimeSpan(startDate, endDate);

        // Scroll the timeline to 6am
        const scrollToTime = new Date(startDate);
        scrollToTime.setHours(6);
        schedulerRef?.current?.instance.scrollToDate(scrollToTime, { block: 'start' });
    }, [data, date, schedulerRef, schedulerRef?.current?.instance]);

    useEffect(() => {
        const { events } = webhookConnector;
        if (!events) {
            return;
        }
        events((updatedDate, id) => {
            if (updatedDate === formatDate(date, 'yyyy-MM-dd')) {
                loadData(date);
                if (id === '' || id === selectedEvent?.id) {
                    setEditEventOpen(false);
                }
            }
        });
    }, [webhookConnector, currentDate, loadData, setEditEventOpen, selectedEvent?.id, date, schedulerRef?.current?.instance]);

    const createDispatchDto = useCallback(
        (data: any) => {
            // if start date shifts to the previous date, set it to midnight of the current date.
            if (data.startDate < startOfDay(currentDate)) {
                data.startDate = startOfDay(currentDate);
            }

            if (data.endDate > endOfDay(currentDate)) {
                data.endDate = endOfDay(currentDate);
            }

            if (data.endDate < data.startDate) {
                data.endDate = add(data.startDate, { hours: 1 });
            }

            // scroll to the start date of the new event
            schedulerRef.current.instance.scrollToDate(data.startDate);

            const dispatch = {
                id: emptyGuid,
                isActive: true,
                createdOn: new Date(),
                orderLineItemId: data.resourceId,
                driverId: data.driverId,
                brokerId: data.brokerId,
                equipmentId: data.equipmentId ?? data.driver?.equipmentId ?? undefined,
                startDate: data.startDate,
                endDate: data.endDate,
                onsiteTime: data.onsiteTime,
                yardTime: data.yardTime,
                clientId: emptyGuid,
                released: data.released,
                haulToAddressLine1: data.haulToAddressLine1,
                haulToAddressLine2: data.haulToAddressLine2,
                haulToCity: data.haulToCity,
                haulToCountry: data.haulToCountry,
                haulToState: data.haulToState,
                haulToZipCode: data.haulToZipCode,
                haulFromAddressLine1: data.haulFromAddressLine1,
                haulFromAddressLine2: data.haulFromAddressLine2,
                haulFromCity: data.haulFromCity,
                haulFromCountry: data.haulFromCountry,
                haulFromState: data.haulFromState,
                haulFromZipCode: data.haulFromZipCode,
                haulToSiteName: data.haulToSiteName,
                haulToRegion: data.haulToRegion,
                haulToRegionId: data.haulToRegionId,
                haulFromSiteName: data.haulFromSiteName,
                haulFromRegion: data.haulFromRegion,
                haulFromRegionId: data.haulFromRegionId,
                driverMemo: data.driverMemo ?? data.orderLineItem?.driverMemo,
                isHaulFromAddressLocked: data.isHaulFromAddressLocked,
                isHaulToAddressLocked: data.isHaulToAddressLocked,
            } as DispatchDto;

            if (data.id !== emptyGuid) {
                dispatch.id = data.id;
                dispatch.isActive = data.isActive;
                dispatch.createdOn = data.createdOn;
                dispatch.clientId = data.clientId;
            }

            return dispatch;
        },
        [currentDate, schedulerRef]
    );

    const onEventStoreUpdate = useCallback(
        ({ record, changes }: { record: DispatchEventModel; changes: any }): void => {
            const data = (record as any).data;

            const originalData = (record as any).originalData;
            if (changes.updatedOn) {

                return;
            }

            if (
                data.id === emptyGuid ||
                !areDateTimesEqual(data.startDate, originalData.startDate) ||
                !areDateTimesEqual(data.endDate, originalData.endDate) ||
                !areDateTimesEqual(data.onsiteTime, originalData.onsiteTime) ||
                !areDateTimesEqual(data.yardTime, originalData.yardTime) ||
                data.orderLineItemId !== originalData.orderLineItemId ||
                data.driverId !== originalData.driverId ||
                data.brokerId !== originalData.brokerId ||
                data.equipmentId !== originalData.equipmentId ||
                data.released !== originalData.released ||
                data.haulToAddressLine1 !== originalData.haulToAddressLine1 ||
                data.haulToAddressLine2 !== originalData.haulToAddressLine2 ||
                data.haulToCity !== originalData.haulToCity ||
                data.haulToCountry !== originalData.haulToCountry ||
                data.haulToState !== originalData.haulToState ||
                data.haulToZipCode !== originalData.haulToZipCode ||
                data.haulFromAddressLine1 !== originalData.haulFromAddressLine1 ||
                data.haulFromAddressLine2 !== originalData.haulFromAddressLine2 ||
                data.haulFromCity !== originalData.haulFromCity ||
                data.haulFromCountry !== originalData.haulFromCountry ||
                data.haulFromState !== originalData.haulFromState ||
                data.haulFromZipCode !== originalData.haulFromZipCode ||
                data.haulToSiteName !== originalData.haulToSiteName ||
                data.haulToRegionId !== originalData.haulToRegionId ||
                data.haulFromSiteName !== originalData.haulFromSiteName ||
                data.haulFromRegionId !== originalData.haulFromRegionId ||
                data.driverMemo !== originalData.driverMemo ||
                data.isHaulFromAddressLocked !== originalData.isHaulFromAddressLocked ||
                data.isHaulToAddressLocked !== originalData.isHaulToAddressLocked ||
                data.resourceId !== originalData.resourceId
            ) {
                const dto = createDispatchDto(data);
                if (dto.brokerId || dto.driverId) {
                    schedulerRef?.current?.instance?.eventStore?.remove(record);
                    updateDispatch(dto);
                }
            }
        },
        [createDispatchDto, updateDispatch, schedulerRef]
    );

    return (
        <>
            {isEditEventOpen && (
                <EventEditor
                    initValues={selectedEvent}
                    isHaulerView={false}
                    handleDelete={() => {
                        if (selectedEvent) {
                            const eventStore = schedulerRef.current.instance.eventStore;
                            eventStore.remove(selectedEvent);
                            deleteDispatch({ id: selectedEvent.getData('id') });
                            setEditEventOpen(false);
                            setSelectedEvent(undefined);
                        }
                    }}
                    open={isEditEventOpen}
                    cancel={() => {
                        setEditEventOpen(false);
                        setSelectedEvent(undefined);
                    }}
                    save={() => {
                        setEditEventOpen(false);
                        setSelectedEvent(undefined);
                    }}
                    disabled={disabled}
                />
            )}
            {isEditLineItemOpen && (
                <OrderLineItemEditor
                    initValues={selectedLineItem}
                    orderLineItemDto={selectedOrderLineItemDto}
                    save={() => {
                        setIsEditLineItemOpen(false);
                        setSelectedLineItem(undefined);
                    }}
                    cancel={() => {
                        setIsEditLineItemOpen(false);
                        setSelectedLineItem(undefined);
                    }}
                    open={true}
                    disabled={false}
                />
            )}
            <div id='main' style={{ position: 'relative' }}>
                <OrderDispatchingViewScheduler
                    schedulerRef={schedulerRef}
                    config={{ ...schedulerConfig }}
                    resources={data?.resources ?? []}
                    events={data?.events ?? []}
                    crudManager={crudManager}
                    onEventStoreUpdate={onEventStoreUpdate}
                />
                <BryntumSplitter />
                <div id='unplannedContainer'>
                    <HaulerGrid schedulerRef={schedulerRef} dataList={data?.haulers ?? []} disabled={disabled} />
                </div>
            </div>
        </>
    );
};

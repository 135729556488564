import { DateHelper, DragHelper, DomHelper, Tooltip, ScrollManager, EventDrag, ResourceModel, DragHelperConfig, Model, Toast } from '@bryntum/scheduler';
import { Grid, Scheduler } from '@bryntum/scheduler';
import { add } from 'date-fns';
import HaulerModel from '../Stores/Models/HaulerModel';
import DispatchEventModel from '../Stores/DispatchEvent/DispatchEventModel';
import { emptyGuid } from '../../../util';

type HaulerDragElementConfig = Partial<DragHelperConfig> & {
    grid: Grid;
    schedule: Scheduler;
    outerElement: HTMLElement;
    eventColor: string;
};

export default class HaulerDragElement extends DragHelper {
    static get configurable() {
        return {
            callOnFunctions: true,
            // Don't drag the actual row element, clone it
            cloneTarget: true,
            // We size the cloned element manually
            autoSizeClonedTarget: true,
            // Only allow drops on the schedule area
            dropTargetSelector: '.b-timeline-subgrid',
            // Only allow drag of row elements inside on the unplanned grid
            targetSelector: '.b-grid-row:not(.b-group-row)',
        };
    }

    public scrollManager!: ScrollManager;
    public grid: Grid;
    public outerElement: HTMLElement;
    public eventColor: string;

    private tip: Tooltip | undefined;
    private _schedule!: Scheduler;

    // We need this construction for TypeScript
    // Also, we cannot use `me` as variable for `this`
    constructor(config: HaulerDragElementConfig) {
        super(config);
        this.grid = config.grid;
        this.schedule = config.schedule;
        this.outerElement = config.outerElement;
        this.eventColor = config.eventColor;
    }

    afterConstruct(): void {
        // Configure DragHelper with schedule's scrollManager to allow scrolling while dragging
        this.scrollManager = this.schedule.scrollManager as ScrollManager;
    }

    createProxy(element: HTMLElement): HTMLDivElement {
        const proxy = document.createElement('div');
        const { schedule } = this;
        const task = this.grid.getRecordFromElement(element) as HaulerModel;
        const durationInPx = schedule.timeAxisViewModel.getDistanceForDuration(50);
        // Fake an event bar
        proxy.classList.add('b-sch-event-wrap', 'b-sch-event', 'b-unassigned-class', `b-sch-${schedule.mode}`);
        proxy.innerHTML = `<div class="b-sch-event b-has-content b-sch-event-withicon">
            <div class="b-sch-event-content">
                <i class="${task.get('iconCls')}"></i> ${task.get('name')}
            </div>
        </div>`;
        if (schedule.mode === 'horizontal') {
            proxy.style.height = `${schedule.rowHeight - 2 * (schedule.resourceMargin as number)}px`;
            proxy.style.width = `${durationInPx}px`;
        } else {
            proxy.style.height = `${durationInPx}px`;
            proxy.style.width = `${schedule.resourceColumnWidth}px`;
        }

        return proxy;
    }

    onDragStart: (event: { source: DragHelper; context: any; event: MouseEvent | TouchEvent }) => boolean | void = ({
        context,
    }: {
        context: {
            task: Model;
            element: HTMLElement;
            grabbed: HTMLElement;
        };
    }) => {
        const me = this;
        const { schedule } = me;
        const { eventDrag }: { eventDrag: EventDrag } = schedule.features;

        // save a reference to the task so we can access it later
        context.task = me.grid.getRecordFromElement(context.grabbed);

        schedule.enableScrollingCloseToEdges(schedule.timeAxisSubGrid);

        // @ts-ignore
        if (eventDrag.showTooltip && !me.tip) {
            me.tip = new Tooltip({
                align: 'b-t',
                // @ts-ignore
                clippedBy: [schedule.timeAxisSubGridElement, schedule.bodyContainer],
                forElement: context.element,
                cls: 'b-popup b-sch-event-tooltip',
            });
        }
    };

    onDrag: (event: { source: DragHelper; context: any; event: MouseEvent }) => boolean | void = ({
        event,
        context,
    }: {
        event: MouseEvent;
        context: {
            task: HaulerModel;
            element: HTMLElement;
            valid: Boolean;
            target: HTMLElement;
            resource: ResourceModel;
        };
    }) => {
        const me = this;
        const { schedule } = me;
        const { task } = context;
        const coordinate = DomHelper[`getTranslate${schedule.mode === 'horizontal' ? 'X' : 'Y'}`](context.element);
        // Coordinates required when used in vertical mode, since it does not use actual columns
        const resource = context.target && schedule.resolveResourceRecord(context.target, [event.offsetX, event.offsetY]);
        let startDate = schedule.getDateFromCoordinate(coordinate, 'round', false);
        if (!startDate) {
            startDate = schedule.startDate;
        }
        if ((resource as any)?.onsiteTime) {
            startDate = new Date((resource as any)?.onsiteTime);
        } else if ((resource as any)?.yardTime) {
            startDate = new Date((resource as any)?.yardTime);
        } else {
            startDate.setHours(6, 0, 0, 0);
        }
        const endDate = add(startDate, { hours: 8 });

        // Don't allow drops anywhere, only allow drops if the drop is on the timeaxis and on top of a Resource
        context.valid =
            Boolean(startDate && resource) &&
            // @ts-ignore
            (schedule.allowOverlap || schedule.isDateRangeAvailable(startDate, endDate, null, resource));

        // Save reference to resource so we can use it in onTaskDrop
        context.resource = resource;

        if (me.tip && context.valid) {
            const dateFormat = schedule.displayDateFormat,
                formattedStartDate = DateHelper.format(startDate, dateFormat),
                formattedEndDate = DateHelper.format(endDate, dateFormat);

            me.tip.html = `
                <div class="b-sch-event-title">${(task as any).name}</div>
                <div class="b-sch-tooltip-startdate">Starts: ${formattedStartDate}</div>
                <div class="b-sch-tooltip-enddate">Ends: ${formattedEndDate}</div>
            `;
            me.tip.showBy(context.element);
        } else if (me.tip) {
            me.tip.hide();
        }
    };

    // Drop callback after a mouse up, take action and transfer the unplanned task to the real EventStore (if it's valid)
    onDrop: (event: { source: DragHelper; context: any }) => boolean | void = ({
        context,
    }: {
        context: {
            task: HaulerModel;
            target: HTMLElement;
            resource: ResourceModel;
            valid: Boolean;
            element: HTMLElement;
        };
    }) => {
        const me = this;
        const { schedule } = me;
        const { task, target, resource, valid } = context;
        me.tip?.hide();

        schedule.disableScrollingCloseToEdges(me.schedule.timeAxisSubGrid);

        // If drop was done in a valid location, set the startDate and transfer the task to the Scheduler event store
        if (valid && target) {
            if ((resource as any).isOrderInReview) {
                Toast.show('This Order is in review and cannot be modified.');
                return;
            }
            const currentDate = schedule.startDate;
            const onsiteTime = (resource as any)?.data?.onsiteTime;
            const yardTime = (resource as any)?.data?.yardTime;
            let date = currentDate;
            if (!!onsiteTime) {
                date = new Date(onsiteTime);
            } else if (!!yardTime) {
                date = new Date(yardTime);
            } else {
                date.setHours(6, 0, 0, 0);
            }

            date.setFullYear(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

            if (date) {
                const assignedTask = new DispatchEventModel({
                    id: emptyGuid,
                    startDate: date,
                    endDate: add(date, { hours: 8 }),
                    orderLineItemId: (resource as any).id,
                    resourceId: (resource as any).id,
                }) as any;
                if ((task as any).haulerType === 'Drivers') {
                    assignedTask.driverId = task.id;
                    assignedTask.equipmentId = (task as any).equipmentId;
                } else {
                    assignedTask.brokerId = task.id;
                }

                assignedTask.assign(resource);
                schedule.eventStore.add(assignedTask);
            }
        }

        if (resource) {
            resource.cls = '';
        }
    };

    set schedule(schedule: Scheduler) {
        this._schedule = schedule;

        // Configure DragHelper with schedule's scrollManager to allow scrolling while dragging
        this.scrollManager = schedule.scrollManager as ScrollManager;
    }

    get schedule(): Scheduler {
        return this._schedule;
    }

    onDragAbort() {
        this.tip?.hide();
    }
}

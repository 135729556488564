import { Badge, FormControl, FormLabel } from "@mui/material";
import { PickersDay, DatePicker } from "@mui/x-date-pickers";
import { isValid } from "date-fns";
import { FC, useState, useEffect } from "react";

export interface IDatePickerWithDataLookupProps {
    date: Date;
    updateDate: (updatedDate: Date) => void;
    daysWithData?: string[];
    handleOnMonthChange?: (newMonth: Date) => void;
    label?: string;
    required?: boolean;
}

export const DatePickerWithDataLookup: FC<IDatePickerWithDataLookupProps> = ({ date, updateDate, daysWithData, handleOnMonthChange, label, required }) => {
    const [highlightedDays, setHighlightedDays] = useState(daysWithData ?? []);
    useEffect(() => {
        setHighlightedDays(daysWithData ?? []);
    }, [daysWithData]);

    const Day = (props) => {
        const { day, outsideCurrentMonth, highlightedDays, ...other } = props;

        const isHighlighted = highlightedDays.includes(day.getDate());

        if (!isHighlighted || outsideCurrentMonth) {
            return <PickersDay day={day} outsideCurrentMonth={outsideCurrentMonth} {...other} />;
        }

        return (
            <Badge key={day.toString()} color='secondary' variant='dot' overlap='circular'>
                <PickersDay day={day} outsideCurrentMonth={outsideCurrentMonth} {...other} />
            </Badge>
        );
    };

    return (
        <FormControl fullWidth required={required} error={required && !isValid(date)}>
            {label && <FormLabel>{label}</FormLabel>}
            <DatePicker
                value={date ?? null}
                onChange={(newDate) => {
                    if (newDate) {
                        updateDate(new Date(newDate));
                    }
                }}
                slots={{
                    day: Day,
                }}
                slotProps={{
                    day: {
                        highlightedDays,
                    } as any,
                }}
                onMonthChange={(newMonth) => {
                    if (handleOnMonthChange) {
                        handleOnMonthChange(newMonth);
                    }
                }}
            />
        </FormControl>
    );
};
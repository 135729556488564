import { ColumnStoreConfig, Model, ResourceModel } from "@bryntum/scheduler";
import { useCallback, useMemo } from "react";
import { OrderLineItemDto } from "../../../../dtos";
import { Link, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getSchedulingStatus } from "../../../../util/getSchedulingStatus";
import { Warning, CheckCircle, Cancel } from "@mui/icons-material";
import { BryntumGridProps } from "@bryntum/scheduler-react";

export function useColumnManager() {
    const navigate = useNavigate();

    const getOrderLineItemFromResourceModel = useCallback((data: { record: Model | ResourceModel }) => {
        return (data.record as any).data as OrderLineItemDto;
    }, []);

    const orderViewSchedulerDefaultColumnConfigs: ColumnStoreConfig = useMemo(() => ({
        data: [
            {
                id: 'customerName',
                field: 'customerName',
                width: 150,
                text: 'Customer',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: false,
                renderer: (data: { record: ResourceModel }) => `${getOrderLineItemFromResourceModel(data).order?.job?.customerName}`,
            },
            {
                id: 'onsiteTime',
                field: 'onsiteTime',
                width: 100,
                text: 'Onsite Time',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: false,
                renderer: (data: { record: ResourceModel }) => {
                    const onsiteTime = getOrderLineItemFromResourceModel(data).onsiteTime;
                    const formattedOnsiteTime = onsiteTime ? new Date(onsiteTime)?.toLocaleTimeString([], { timeStyle: 'short' }) : 'ASAP';
                    return `${formattedOnsiteTime}`;
                },
                groupRenderer: ({ groupRowFor }) => {
                    return groupRowFor !== '!!novalue!!' ? new Date(groupRowFor).toLocaleTimeString([], { timeStyle: 'short' }) : 'ASAP';
                },
            },
            {
                id: 'orderNumber',
                field: 'orderNumber',
                width: 100,
                text: 'Order #',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: false,
                renderer: (data: { record: ResourceModel }) => (
                    <Typography>
                        <Link
                            href={`/job/${getOrderLineItemFromResourceModel(data).jobId}/order/${getOrderLineItemFromResourceModel(data).orderId}`}
                            variant='body2'
                            onClick={() => {
                                navigate(`/job/${getOrderLineItemFromResourceModel(data).jobId}/order/${getOrderLineItemFromResourceModel(data).orderId}`);
                            }}>
                            #{getOrderLineItemFromResourceModel(data).orderNumber}
                        </Link>
                    </Typography>
                ),
                sortable(lhs, rhs) {
                    return (lhs?.data?.orderNumber ?? 'N/A') < (rhs?.data?.orderNumber ?? 'N/A') ? -1 : 1;
                },
            },
            {
                id: 'projectName',
                field: 'projectName',
                width: 150,
                text: 'Project',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: false,
            },
            {
                id: 'jobName',
                field: 'jobName',
                width: 150,
                text: 'Job',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: false,
            },
            {
                id: 'description',
                field: 'lineItem.description',
                width: 100,
                text: 'Description',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: false,
            },
            {
                id: 'equipmentType',
                field: 'equipmentType.type',
                width: 50,
                text: 'Equipment Type',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: false,
            },
            {
                id: 'requirements',
                field: 'requirements',
                width: 100,
                text: 'Requirements',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: false,
            },
            {
                id: 'siteName',
                field: 'siteName',
                width: 50,
                text: 'Site',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: true,
                renderer: (data: { record: ResourceModel }) => `${getOrderLineItemFromResourceModel(data).siteName ?? 'N/A'}`,
            },
            {
                id: 'regionName',
                field: 'regionName',
                width: 50,
                text: 'Region',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: true,
                renderer: (data: { record: ResourceModel }) => `${getOrderLineItemFromResourceModel(data).regionName ?? 'N/A'}`,
            },
            {
                id: 'orderLineItemNumber',
                field: 'orderLineItemNumber',
                width: 50,
                text: 'Line Item #',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: true,
                renderer: (data: { record: ResourceModel }) =>
                    `#${getOrderLineItemFromResourceModel(data).orderLineItemNumber}/${getOrderLineItemFromResourceModel(data).order?.orderLineItemCount}`,
                sortable(lhs, rhs) {
                    return (lhs?.data?.orderLineItemNumber ?? 'N/A') < (rhs?.data?.orderLineItemNumber ?? 'N/A') ? -1 : 1;
                },
            },
            {
                id: 'orderer',
                field: 'orderer',
                width: 150,
                text: 'Orderer',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: true,
                renderer: (data: { record: ResourceModel }) => `${getOrderLineItemFromResourceModel(data).orderer}`,
            },
            {
                id: 'jobNumber',
                field: 'jobNumber',
                width: 50,
                text: 'Job #',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: true,
                renderer: (data: { record: ResourceModel }) => (
                    <Typography>
                        <Link
                            href={`/job/${getOrderLineItemFromResourceModel(data).jobId}`}
                            variant='body2'
                            onClick={() => {
                                navigate(`/job/${getOrderLineItemFromResourceModel(data).jobId}`);
                            }}>
                            #{getOrderLineItemFromResourceModel(data).jobNumber}
                        </Link>
                    </Typography>
                ),
                sortable(lhs, rhs) {
                    return (lhs?.data?.jobNumber ?? 'N/A') < (rhs?.data?.jobNumber ?? 'N/A') ? -1 : 1;
                },
            },
            {
                id: 'dispatchingIntervalTimeFormatted',
                field: 'dispatchingIntervalTimeFormatted',
                width: 50,
                text: 'Interval Time',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: true,
            },
            {
                id: 'yardTime',
                field: 'yardTime',
                width: 50,
                text: 'Yard Time',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: true,
                renderer: (data: { record: ResourceModel }) => {
                    const yardTime = getOrderLineItemFromResourceModel(data).yardTime;
                    const formattedYardTime = yardTime ? new Date(yardTime)?.toLocaleTimeString([], { timeStyle: 'short' }) : 'ASAP';
                    return `${formattedYardTime}`;
                },
                groupRenderer: ({ groupRowFor }) => {
                    return groupRowFor !== '!!novalue!!' ? new Date(groupRowFor).toLocaleTimeString([], { timeStyle: 'short' }) : 'ASAP';
                },
            },
            {
                id: 'isScheduled',
                field: 'isScheduled',
                width: 50,
                text: 'Assigned',
                enableCellContextMenu: false,
                readOnly: true,
                htmlEncode: false,
                hidden: false,
                renderer: (data: { record: ResourceModel }) => {
                    var record = getOrderLineItemFromResourceModel(data);
                    var tooltipText: string;
                    var icon: JSX.Element;
                    switch (getSchedulingStatus(record.isScheduled ?? false, record.isOverbooked ?? false)) {
                        case 'Overbooked':
                            icon = <Warning sx={{ width: 24, height: 24, color: 'orange' }} />;
                            tooltipText = 'Overbooked';
                            break;
                        case 'Scheduled':
                            icon = <CheckCircle sx={{ width: 24, height: 24, color: 'green' }} />;
                            tooltipText = 'Assigned';
                            break;
                        case 'Unscheduled':
                            icon = <Cancel sx={{ width: 24, height: 24, color: '#A3271F' }} />;
                            tooltipText = 'Unassigned';
                            break;
                        default:
                            icon = <Cancel sx={{ width: 24, height: 24, color: '#A3271F' }} />;
                            tooltipText = 'Unassigned';
                            break;
                    }

                    return <Tooltip title={tooltipText}>{icon}</Tooltip>;
                },
                align: 'center' as 'center' | 'left' | 'right' | 'start' | 'end' | undefined, // Weird hack to resolve bryntum not recognizing the alignment value correctly,
                sortable(lhs, rhs) {
                    return (lhs?.data?.isScheduled ?? 'N/A') < (rhs?.data?.isScheduled ?? 'N/A') ? -1 : 1;
                },
            },
        ],
    }), [getOrderLineItemFromResourceModel, navigate]);

    const haulerGridConfig: BryntumGridProps = useMemo(() => ({
        stateId: 'orderDispatch_haulerGridState',
        rowHeight: 80,
        minWidth: 300,
        cls: 'b-unplannedgrid1',
        groupFeature: {
            field: 'haulerType',
            ascending: false,
        },
        sortFeature: {
            field: 'code',
            ascending: true,
        },
        columns: {
            data: [
                {
                    id: 'name',
                    text: 'Name',
                    flex: 1,
                    field: 'name',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: false,
                },
                {
                    id: 'assignedEquipment',
                    text: 'Assigned Equipment',
                    flex: 1,
                    field: 'assignedEquipment',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: false,
                },
                {
                    id: 'isScheduled',
                    text: 'Scheduled',
                    field: 'isScheduled',
                    width: 50,
                    enableCellContextMenu: false,
                    readOnly: true,
                    htmlEncode: false,
                    hidden: false,
                    renderer: ({ record }: { record: any }) => {
                        var tooltipText: string;
                        var icon: JSX.Element;
                        switch (getSchedulingStatus(record.isScheduled ?? false, record.isOverbooked ?? false)) {
                            case 'Overbooked':
                                icon = <Warning sx={{ width: 24, height: 24, color: 'orange' }} />;
                                tooltipText = 'Overbooked';
                                break;
                            case 'Scheduled':
                                icon = <CheckCircle sx={{ width: 24, height: 24, color: 'green' }} />;
                                tooltipText = 'Scheduled';
                                break;
                            case 'Unscheduled':
                                icon = <Cancel sx={{ width: 24, height: 24, color: '#A3271F' }} />;
                                tooltipText = 'Unscheduled';
                                break;
                            default:
                                icon = <Cancel sx={{ width: 24, height: 24, color: '#A3271F' }} />;
                                tooltipText = 'Unscheduled';
                                break;
                        }

                        return <Tooltip title={tooltipText}>{icon}</Tooltip>;
                    },
                    align: 'center' as 'center' | 'left' | 'right' | 'start' | 'end' | undefined, // Weird hack to resolve bryntum not recognizing the alignment value correctly,
                    sortable(lhs, rhs) {
                        return (lhs?.data?.isScheduled ?? 'N/A') < (rhs?.data?.isScheduled ?? 'N/A') ? -1 : 1;
                    },
                },
                {
                    id: 'code',
                    text: 'Code',
                    flex: 1,
                    field: 'code',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: false,
                },
                {
                    id: 'haulerType',
                    text: 'Hauler Type',
                    flex: 1,
                    field: 'haulerType',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: true,
                },
                {
                    id: 'hireDate',
                    text: 'Hire Date',
                    flex: 1,
                    field: 'hireDate',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: true,
                    sortable: (lhs, rhs) => {
                        const lhsDate = new Date(lhs.data.hireDate);
                        const rhsDate = new Date(rhs.data.hireDate);
                        return lhsDate.getTime() - rhsDate.getTime();
                    },
                },
            ],
        },
        stripeFeature: true,
    }), []);

    const haulerViewSchedulerDefaultColumConfig: ColumnStoreConfig = useMemo(() => ({
        data: [
            {
                id: 'name',
                text: 'Name',
                width: 50,
                field: 'name',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: false,
            },
            {
                id: 'assignedEquipment',
                text: 'Assigned Equipment',
                width: 50,
                field: 'assignedEquipment',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: false,
            },
            {
                id: 'isScheduled',
                text: 'Scheduled',
                width: 50,
                field: 'isScheduled',
                enableCellContextMenu: false,
                readOnly: true,
                htmlEncode: false,
                renderer: ({ record }: { record: any }) => {
                    var tooltipText: string;
                    var icon: JSX.Element;
                    switch (getSchedulingStatus(record.isScheduled ?? false, record.isOverbooked ?? false)) {
                        case 'Overbooked':
                            icon = <Warning sx={{ width: 24, height: 24, color: 'orange' }} />;
                            tooltipText = 'Overbooked';
                            break;
                        case 'Scheduled':
                            icon = <CheckCircle sx={{ width: 24, height: 24, color: 'green' }} />;
                            tooltipText = 'Scheduled';
                            break;
                        case 'Unscheduled':
                            icon = <Cancel sx={{ width: 24, height: 24, color: '#A3271F' }} />;
                            tooltipText = 'Unscheduled';
                            break;
                        default:
                            icon = <Cancel sx={{ width: 24, height: 24, color: '#A3271F' }} />;
                            tooltipText = 'Unscheduled';
                            break;
                    }

                    return <Tooltip title={tooltipText}>{icon}</Tooltip>;
                },
                align: 'center' as 'center' | 'left' | 'right' | 'start' | 'end' | undefined, // Weird hack to resolve bryntum not recognizing the alignment value correctly
                hidden: false,
            },
            {
                id: 'code',
                text: 'Code',
                width: 50,
                field: 'code',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: false,
            },
            {
                id: 'haulerType',
                text: 'Hauler Type',
                width: 50,
                field: 'haulerType',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: true,
            },
            {
                text: 'Hire Date',
                width: 50,
                field: 'hireDate',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: true,
                sortable: (lhs, rhs) => {
                    const lhsDate = new Date(lhs.data.hireDate);
                    const rhsDate = new Date(rhs.data.hireDate);
                    return lhsDate.getTime() - rhsDate.getTime();
                },
            },
        ],
    }), []);

    const orderLineItemGridConfig: BryntumGridProps = useMemo(() => ({
        stateId: 'haulerManagement_orderGridState',
        rowHeight: 80,
        minWidth: 300,
        cls: 'b-unplannedgrid1',
        groupFeature: {
            field: 'customerName',
            ascending: false,
        },
        sortFeature: {
            field: 'lineItem.description',
            ascending: false,
        },
        columns: {
            data: [
                {
                    id: 'customerName',
                    field: 'customerName',
                    width: 50,
                    text: 'Customer',
                    enableCellContextMenu: false,
                    readOnly: true,
                    renderer: (data: { record: Model }) => `${getOrderLineItemFromResourceModel(data).customerName}`,
                    hidden: false,
                },
                {
                    id: 'onsiteTime',
                    field: 'onsiteTime',
                    width: 50,
                    text: 'Onsite Time',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: false,
                    renderer: (data: { record: Model }) => {
                        const onsiteTime = getOrderLineItemFromResourceModel(data).onsiteTime;
                        const formattedOnsiteTime = onsiteTime ? new Date(onsiteTime)?.toLocaleTimeString([], { timeStyle: 'short' }) : 'ASAP';
                        return `${formattedOnsiteTime}`;
                    },
                    groupRenderer: ({ groupRowFor }) => {
                        return groupRowFor !== '!!novalue!!' ? new Date(groupRowFor).toLocaleTimeString([], { timeStyle: 'short' }) : 'ASAP';
                    },
                },
                {
                    id: 'orderNumber',
                    field: 'orderNumber',
                    width: 50,
                    text: 'Order #',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: false,
                    renderer: (data: { record: Model }) => (
                        <Typography>
                            <Link
                                href={`/job/${getOrderLineItemFromResourceModel(data).jobId}/order/${getOrderLineItemFromResourceModel(data).orderId}`}
                                variant='body2'
                                onClick={() => {
                                    navigate(`/job/${getOrderLineItemFromResourceModel(data).jobId}/order/${getOrderLineItemFromResourceModel(data).orderId}`);
                                }}>
                                #{getOrderLineItemFromResourceModel(data).orderNumber}
                            </Link>
                        </Typography>
                    ),
                    sortable(lhs: any, rhs: any) {
                        return (lhs?.data?.orderNumber ?? 'N/A') < (rhs?.data?.orderNumber ?? 'N/A') ? -1 : 1;
                    },
                },
                {
                    id: 'projectName',
                    field: 'projectName',
                    width: 50,
                    text: 'Project',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: false,
                },
                {
                    id: 'jobName',
                    field: 'jobName',
                    width: 50,
                    text: 'Job',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: false,
                },
                {
                    id: 'description',
                    field: 'lineItem.description',
                    width: 50,
                    text: 'Description',
                    enableCellContextMenu: false,
                    hidden: false,
                    readOnly: false,
                },
                {
                    id: 'equipmentType',
                    field: 'equipmentType.type',
                    width: 50,
                    text: 'Equipment Type',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: false,
                },
                {
                    id: 'requirements',
                    field: 'requirements',
                    width: 50,
                    text: 'Requirements',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: false,
                },
                {
                    id: 'isScheduled',
                    field: 'isScheduled',
                    width: 50,
                    text: 'Assigned',
                    enableCellContextMenu: false,
                    readOnly: true,
                    htmlEncode: false,
                    hidden: false,
                    renderer: (data: { record: ResourceModel }) => {
                        var record = getOrderLineItemFromResourceModel(data);
                        var tooltipText: string;
                        var icon: JSX.Element;
                        switch (getSchedulingStatus(record.isScheduled ?? false, record.isOverbooked ?? false)) {
                            case 'Overbooked':
                                icon = <Warning sx={{ width: 24, height: 24, color: 'orange' }} />;
                                tooltipText = 'Overbooked';
                                break;
                            case 'Scheduled':
                                icon = <CheckCircle sx={{ width: 24, height: 24, color: 'green' }} />;
                                tooltipText = 'Assigned';
                                break;
                            case 'Unscheduled':
                                icon = <Cancel sx={{ width: 24, height: 24, color: '#A3271F' }} />;
                                tooltipText = 'Unassigned';
                                break;
                            default:
                                icon = <Cancel sx={{ width: 24, height: 24, color: '#A3271F' }} />;
                                tooltipText = 'Unassigned';
                                break;
                        }
    
                        return <Tooltip title={tooltipText}>{icon}</Tooltip>;
                    },
                    align: 'center' as 'center' | 'left' | 'right' | 'start' | 'end' | undefined, // Weird hack to resolve bryntum not recognizing the alignment value correctly,
                    sortable(lhs, rhs) {
                        return (lhs?.data?.isScheduled ?? 'N/A') < (rhs?.data?.isScheduled ?? 'N/A') ? -1 : 1;
                    },
                },
                {
                    id: 'siteName',
                    field: 'siteName',
                    width: 50,
                    text: 'Site',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: true,
                    renderer: (data: { record: Model }) => `${getOrderLineItemFromResourceModel(data).siteName ?? 'N/A'}`,
                },
                {
                    id: 'regionName',
                    field: 'regionName',
                    width: 50,
                    text: 'Region',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: true,
                    renderer: (data: { record: Model }) => `${getOrderLineItemFromResourceModel(data).regionName ?? 'N/A'}`,
                },
                {
                    id: 'orderLineItemNumber',
                    field: 'orderLineItemNumber',
                    width: 50,
                    text: 'Line Item #',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: true,
                    renderer: (data: { record: Model }) =>
                        `#${getOrderLineItemFromResourceModel(data).orderLineItemNumber}/${getOrderLineItemFromResourceModel(data).order?.orderLineItemCount}`,
                    sortable(lhs: any, rhs: any) {
                        return (lhs?.data?.orderLineItem?.orderLineItemNumber ?? 'N/A') < (rhs?.data?.orderLineItem?.orderLineItemNumber ?? 'N/A') ? -1 : 1;
                    },
                },
                {
                    id: 'orderer',
                    field: 'orderer',
                    width: 150,
                    text: 'Orderer',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: true,
                    renderer: (data: { record: Model }) => `${getOrderLineItemFromResourceModel(data).orderer}`,
                },
                {
                    id: 'jobNumber',
                    field: 'jobNumber',
                    width: 50,
                    text: 'Job #',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: true,
                    renderer: (data: { record: Model }) => (
                        <Typography>
                            <Link
                                href={`/job/${getOrderLineItemFromResourceModel(data).jobId}`}
                                variant='body2'
                                onClick={() => {
                                    navigate(`/job/${getOrderLineItemFromResourceModel(data).jobId}`);
                                }}>
                                #{getOrderLineItemFromResourceModel(data).jobNumber}
                            </Link>
                        </Typography>
                    ),
                    sortable(lhs: any, rhs: any) {
                        return (lhs?.data?.jobNumber ?? 'N/A') < (rhs?.data?.jobNumber ?? 'N/A') ? -1 : 1;
                    },
                },
                {
                    id: 'dispatchingIntervalTimeFormatted',
                    field: 'dispatchingIntervalTimeFormatted',
                    width: 50,
                    text: 'Interval Time',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: true,
                },

                {
                    id: 'yardTime',
                    field: 'yardTime',
                    width: 50,
                    text: 'Yard Time',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: true,
                    renderer: (data: { record: Model }) => {
                        const yardTime = getOrderLineItemFromResourceModel(data).yardTime;
                        const formattedYardTime = yardTime ? new Date(yardTime)?.toLocaleTimeString([], { timeStyle: 'short' }) : 'ASAP';
                        return `${formattedYardTime}`;
                    },
                    groupRenderer: ({ groupRowFor }) => {
                        return groupRowFor !== '!!novalue!!' ? new Date(groupRowFor).toLocaleTimeString([], { timeStyle: 'short' }) : 'ASAP';
                    },
                },
            ],
        },
        stripeFeature: true,
    }), [getOrderLineItemFromResourceModel, navigate]);

    return {
        orderViewSchedulerDefaultColumnConfigs,
        haulerGridConfig,
        haulerViewSchedulerDefaultColumConfig,
        orderLineItemGridConfig
    }
}
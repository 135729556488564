import { OrderLineItemDto } from '../../../dtos';

export const getOrderLineItemTooltipData = (orderLineItem: OrderLineItemDto) => {

    const convertMinsToHrsMins = (mins: number) => {
        let h = Math.floor(mins / 60);
        let m = mins % 60;
        let hours = h < 10 ? '0' + h : h;
        let minutes = m < 10 ? '0' + m : m;
        return `${hours}:${minutes}`;
    };

    let intervalTime = '';
    if (orderLineItem?.dispatchingIntervalTime) {
        intervalTime = convertMinsToHrsMins(orderLineItem.dispatchingIntervalTime!);
    }

    const getHaulToAddressForOrderLineItem = (orderLineItem: OrderLineItemDto) => {
        let address = "";

        const addressParts = [
            orderLineItem.haulToSiteName,
            orderLineItem.haulToRegion?.name,
            orderLineItem.haulToAddressLine1,
            orderLineItem.haulToAddressLine2
        ];

        address += addressParts.filter(part => part && part.trim() !== "").join("<br> ");

        if (orderLineItem.haulToCity && orderLineItem.haulToCity.trim() !== "") {
            address += `<br> ${orderLineItem.haulToCity}`;
            if (orderLineItem.haulToState && orderLineItem.haulToState.trim() !== "") {
                address += `,`;
            }
        }
        if (orderLineItem.haulToState && orderLineItem.haulToState.trim() !== "") {
            if (!orderLineItem.haulToCity || orderLineItem.haulToCity.trim() === "") {
                address += `<br>`;
            }
            address += ` ${orderLineItem.haulToState}`;
        }
        if (orderLineItem.haulToZipCode && orderLineItem.haulToZipCode.trim() !== "") {
            address += ` ${orderLineItem.haulToZipCode}`;
        }

        if (address.startsWith("<br>")) {
            address = address.substring(4);
        }

        return address;
    };

    const getHaulFromAddressForOrderLineItem = (orderLineItem: OrderLineItemDto) => {
        let address = "";

        const addressParts = [
            orderLineItem.haulFromSiteName,
            orderLineItem.haulFromRegion?.name,
            orderLineItem.haulFromAddressLine1,
            orderLineItem.haulFromAddressLine2
        ];

        address += addressParts.filter(part => part && part.trim() !== "").join("<br> ");

        if (orderLineItem.haulFromCity && orderLineItem.haulFromCity.trim() !== "") {
            address += `<br> ${orderLineItem.haulFromCity}`;
            if (orderLineItem.haulFromState && orderLineItem.haulFromState.trim() !== "") {
                address += `,`;
            }
        }
        if (orderLineItem.haulFromState && orderLineItem.haulFromState.trim() !== "") {
            if (!orderLineItem.haulFromCity || orderLineItem.haulFromCity.trim() === "") {
                address += `<br>`;
            }
            address += ` ${orderLineItem.haulFromState}`;
        }
        if (orderLineItem.haulFromZipCode && orderLineItem.haulFromZipCode.trim() !== "") {
            address += ` ${orderLineItem.haulFromZipCode}`;
        }

        if (address.startsWith("<br>")) {
            address = address.substring(4);
        }

        return address;
    };

    let toolTip = `<table class="tooltip-table">`
    toolTip = toolTip.concat(`
                                <tr>
                                    <td class="tooltip-cell">Customer: ${orderLineItem?.order?.job?.customerName ?? ''}</td>
                                    <td class="tooltip-cell">Line Item #: ${orderLineItem?.orderLineItemNumber}</td>
                                </tr>
                                <tr>
                                    <td class="tooltip-cell" colspan="2">Orderer: ${orderLineItem?.order?.orderer ?? ''}</td>
                                </tr>
                                <tr>
                                    <td class="tooltip-cell" colspan="2">Project Name: ${orderLineItem?.projectName ?? ''}</td>
                                </tr>
                                <tr>
                                    <td class="tooltip-cell" colspan="2">Job Name: ${orderLineItem?.jobName ?? ''}</td>
                                </tr>
                                <tr>
                                    <td class="tooltip-cell">Job #: ${orderLineItem?.order?.job?.jobNumber ?? ''}</td>
                                    <td class="tooltip-cell">Order #: ${orderLineItem?.order?.orderNumber ?? ''}</td>
                                </tr>
                                <tr>
                                    <td class="tooltip-cell">Description: ${orderLineItem?.lineItem?.description ?? ''}</td>
                                    <td class="tooltip-cell">Equipment Type: ${orderLineItem?.equipmentType?.type ?? ''}</td>
                                </tr>
                                <tr>
                                    <td class="tooltip-cell" colspan="2">Requirements: ${orderLineItem?.requirements ?? ''}</td>
                                </tr>
                                <tr>
                                    <td class="tooltip-cell">Onsite Time: ${orderLineItem?.onsiteTime ? new Date(orderLineItem?.onsiteTime).toLocaleTimeString() : 'ASAP'}</td>
                                    <td class="tooltip-cell">Yard Time: ${orderLineItem?.yardTime ? new Date(orderLineItem?.yardTime).toLocaleTimeString() : 'ASAP'}</td>
                                </tr>
                                <tr>
                                    <td class="tooltip-cell">Interval Time: ${intervalTime}</td>
                                </tr>
                                <tr>
                                    <td class="tooltip-cell" colspan="2">Haul To: ${getHaulToAddressForOrderLineItem(orderLineItem)}</td>
                                </tr>
                                <tr>
                                    <td class="tooltip-cell" colspan="2">Haul From: ${getHaulFromAddressForOrderLineItem(orderLineItem)}</td>
                                </tr>
                                <tr>
                                    <td class="tooltip-cell" colspan="2">Internal Memo: ${orderLineItem?.memo ?? ''}</td>
                                </tr>
                                <tr>
                                    <td class="tooltip-cell" colspan="2">Dispatch Memo: ${orderLineItem?.dispatchMemo ?? ''}</td>
                                </tr>
                                <tr>
                                    <td class="tooltip-cell" colspan="2">Driver Memo: ${orderLineItem?.driverMemo ?? ''}</td>
                                </tr>
                        </table>`);

    return toolTip;

}
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CloneQuoteDto, LineItemDto, QuoteDto } from '../../dtos';
import {
    AuthenticatedComponent,
    DataTableColumn,
    PaginatedProps,
    StandardListViewV2,
    UserPermissionContext,
    useFailedActionSnackbar,
    useSuccessfulActionSnackbar,
} from '../../Components/CoreLib/library';
import { Article, Assignment, ContentCopy, ControlPointDuplicate, DownloadForOffline, ThumbUp } from '@mui/icons-material';
import {
    useArchiveQuoteBulkDeleteMutation,
    useCreateJobMutation,
    useCreateQuoteCloneMutation,
    useCreateQuoteCopyMutation,
    useCreateQuotePdfMutation,
    useGetQuoteQuery,
    useLazyGetJobQuery,
    useQuoteBulkArchiveMutation,
    useQuoteBulkUnarchiveMutation,
    useUpdateQuoteAcceptMutation,
} from '../../store/generated/generatedApi';
import { QuoteType, usePageTitleSetter } from '../../util';
import { CircularProgress, Link, Menu, MenuItem, Typography } from '@mui/material';
import { AcceptQuoteLineItemsDialog } from '../../Components/Forms/QuoteForm';
import { CloneQuoteDialog } from './CloneQuoteDialog';
import { AppraisalType } from '../../dtos/generated/AppraisalType';
import { QuoteFilterDialog } from './Components';
import { useQuoteFilters } from './Utils';
import { ConfirmDialog } from '../../Components/Core/ConfirmDialog';
import { DEFAULT_JOB } from '../../Components/Forms/JobForm/useJobForm';
import { CheckCircle, Cancel } from '@mui/icons-material';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'QUOTE_NUMBER',
    sortAsc: false,
    page: 0,
    pageSize: 25,
};

export interface IQuoteListViewProps {
    dashboardView?: boolean;
}

export const QuoteListView: FC<IQuoteListViewProps> = ({ dashboardView }) => {
    const navigate = useNavigate();
    usePageTitleSetter('Quotes', !dashboardView);
    const [isFilterDialogVisible, setIsFilterDialogVisible] = useState(false);
    const { customerId } = useParams();
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [isAcceptLineItemsDialogOpen, setIsAcceptLineItemsDialogOpen] = useState(false);
    const [selectedQuote, setSelectedQuote] = useState<QuoteDto>();
    const [isCloneDialogOpen, setIsCloneDialogOpen] = useState(false);
    const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);
    const [isJobDialogOpen, setIsJobDialogOpen] = useState(false);
    const { permissions } = useContext(UserPermissionContext);

    const { quoteFilters, handleSearchTermFilterChange, getFilterChips, handleRemoveFilterChip } = useQuoteFilters();

    const [getQuotePdf, { isSuccess, isError: isErrorPdf, error: pdfGenerationError, isLoading: pdfLoading, reset: resetPdf }] = useCreateQuotePdfMutation();
    useSuccessfulActionSnackbar('created', 'quote PDF', isSuccess, resetPdf);
    useFailedActionSnackbar('create', 'quote PDF', isErrorPdf, resetPdf, pdfGenerationError);

    const [acceptQuote, { isSuccess: isQuoteAcceptSuccess, isError: isQuoteAcceptError, isLoading: isAcceptingQuote, reset: resetAcceptQuote }] =
        useUpdateQuoteAcceptMutation();
    useSuccessfulActionSnackbar('accepted', 'quote', isQuoteAcceptSuccess, resetAcceptQuote);
    useFailedActionSnackbar('accept', 'quote', isQuoteAcceptError, resetAcceptQuote);

    const [
        duplicateQuote,
        {
            data: duplicatedQuote,
            isSuccess: isQuoteDuplicateSuccess,
            isError: isQuoteDuplicateSuccessError,
            isLoading: isDuplicatingQuote,
            reset: resetDuplicateQuote,
        },
    ] = useCreateQuoteCloneMutation();
    useSuccessfulActionSnackbar('cloned', 'quote', isQuoteDuplicateSuccess, resetDuplicateQuote);
    useFailedActionSnackbar('clone', 'quote', isQuoteDuplicateSuccessError, resetDuplicateQuote);

    const [
        copyQuote,
        {
            data: copiedQuote,
            isSuccess: isQuoteCopySuccess,
            isError: isQuoteCopySuccessError,
            isLoading: isCopyingQuote,
            reset: resetCopyQuote,
        },
    ] = useCreateQuoteCopyMutation();
    useSuccessfulActionSnackbar('copied', 'quote', isQuoteCopySuccess, resetCopyQuote);
    useFailedActionSnackbar('copy', 'quote', isQuoteCopySuccessError, resetCopyQuote);

    useEffect(() => {
        if (isQuoteDuplicateSuccess && duplicatedQuote) {
            resetDuplicateQuote();
            window.open(`/quote/${duplicatedQuote?.id}`, '_blank');
        }
    }, [isQuoteDuplicateSuccess, duplicatedQuote, resetDuplicateQuote]);

    useEffect(() => {
        if (isQuoteCopySuccess && copiedQuote) {
            resetCopyQuote();
            window.open(`/quote/${copiedQuote?.id}`, '_blank');
        }
    }, [isQuoteCopySuccess, copiedQuote, resetCopyQuote]);

    const [createJob, { data: newJob, isError: isErrorCreateJob, isSuccess: isSuccessCreateJob, reset: resetCreateJob }] = useCreateJobMutation();
    useSuccessfulActionSnackbar('created', 'job', isSuccessCreateJob, resetCreateJob);
    useFailedActionSnackbar('create', 'job', isErrorCreateJob, resetCreateJob);

    const [getJobs, { isLoading: jobsLoading }] = useLazyGetJobQuery();

    useEffect(() => {
        if (isSuccessCreateJob && newJob) {
            setIsJobDialogOpen(false);
            if (selectedQuote?.appraisalType === AppraisalType.Estimate) {
                window.open(`/job/${newJob?.id}/order/create`, '_blank');
            } else {
                window.open(`/job/${newJob?.id}`, '_blank');
            }
        }
    }, [isSuccessCreateJob, newJob, selectedQuote?.appraisalType]);

    const getTypeLabel = useCallback((row: QuoteDto) => {
        const typeLabel = row?.type === 'Standard' ? '' : ' (FOB)';

        let appraisalType = '';
        switch (row.appraisalType) {
            case AppraisalType.Quote:
                appraisalType = 'Quote';
                break;
            case AppraisalType.Estimate:
                appraisalType = 'Estimate';
                break;
            case AppraisalType.MasterPriceList:
                appraisalType = 'Master Price List';
                break;

            default:
                break;
        }

        return `${appraisalType}${typeLabel}`;
    }, []);

    const tableColumns: DataTableColumn<QuoteDto>[] = [
        { key: 'jobName', label: 'Job Name', sortKey: 'JOB_NAME' },
        { key: 'projectName', label: 'Project Name', sortKey: 'PROJECT_NAME' },
        { key: 'customerName', label: 'Customer', sortKey: 'CUSTOMER', fieldMapper: (row) => row.customer?.name },
        { key: 'addressLine1', label: 'Address', sortKey: 'ADDRESS_LINE_1' },
        { key: 'city', label: 'City', sortKey: 'CITY' },
        { key: 'accepted', label: 'Accepted?', unsorted: true, align: 'center', sortKey: 'ACCEPTED_ON', fieldMapper: (row) => (
            row.acceptedOn ? <CheckCircle sx={{ width: 24, height: 24, color: 'green' }}/> : <Cancel sx={{ width: 24, height: 24, color: '#A3271F' }} />
        ) },
        { key: 'quoteNumber', label: 'Quote #', sortKey: 'QUOTE_NUMBER' },
        {
            key: 'jobNumber',
            label: 'Job #',
            sortKey: 'JOB_NUMBER',
            fieldMapper: (row) => (
                getTypeLabel(row) !== "Master Price List" ? <Typography>
                    {permissions.includes('edit:job') ? (
                        <Link
                            href={`/job/${row.jobId}`}
                            variant='body2'
                            onClick={() => {
                                navigate(`/job/${row.jobId}`);
                            }}>
                            {row.jobNumber + (row.isCurrentForJob ? ' (Current)' : '')}
                        </Link>
                    ) : (
                        <>{row.jobNumber}</>
                    )}
                </Typography> : <></>
            ),
        },
        { key: 'poNumber', label: 'PO #', sortKey: 'PO_NUMBER' },
        {
            key: 'quoteDate',
            label: 'Quote Date',
            sortKey: 'QUOTE_DATE',
            fieldMapper: (row) => (row.quoteDate ? new Date(row.quoteDate.toString()).toLocaleDateString() : ''),
        },
        {
            key: 'expirationDate',
            label: 'Expiration Date',
            sortKey: 'EXPIRATION_DATE',
            fieldMapper: (row) => (row.expirationDate ? new Date(row.expirationDate.toString()).toLocaleDateString() : ''),
        },
        { key: 'appraisalType', label: 'Type', sortKey: 'APPRAISAL_TYPE', fieldMapper: (row) => getTypeLabel(row) },
    ];

    const generatePdf = useCallback(
        (id?: string) => {
            if (!pdfLoading && id) {
                getQuotePdf({ id: id }).then((response: any) => {
                    if (response?.data) {
                        window.open(response?.data.url, '_blank');
                    }
                });
            }
        },
        [getQuotePdf, pdfLoading]
    );

    const handleTableEdit = (id: string) => {
        if (customerId) {
            navigate(`/customer/${customerId}/quote/${id}`);
        } else {
            navigate(`/quote/${id}`);
        }
    };

    const handleMenuOpen = (event?: any) => {
        if (event) {
            setMenuAnchorEl(event.currentTarget);
            setMenuOpen(true);
        }
    };

    const handleCreate = useCallback(
        (type: string, appraisalType: AppraisalType) => {
            if (customerId) {
                navigate(`/customer/${customerId}/quote/create/${type}/${appraisalType}`);
            } else {
                navigate(`/quote/create/${type}/${appraisalType}`);
            }
        },
        [customerId, navigate]
    );

    const renderAddMenu = () => {
        return (
            <Menu
                anchorEl={menuAnchorEl}
                open={menuOpen}
                onClose={() => {
                    setMenuOpen(false);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <AuthenticatedComponent
                    requiredPermissions={['create:lineItem']}
                    children={
                        <>
                            <MenuItem onClick={() => handleCreate(QuoteType.Standard, AppraisalType.Quote)}>Quote (Standard)</MenuItem>
                            <MenuItem onClick={() => handleCreate(QuoteType.FOB, AppraisalType.Quote)}>Quote (FOB)</MenuItem>
                            <MenuItem onClick={() => handleCreate(QuoteType.Standard, AppraisalType.Estimate)}>Estimate (Standard)</MenuItem>
                            <MenuItem onClick={() => handleCreate(QuoteType.FOB, AppraisalType.Estimate)}>Estimate (FOB)</MenuItem>
                            <MenuItem onClick={() => handleCreate(QuoteType.Standard, AppraisalType.MasterPriceList)}>Master Price List (Standard)</MenuItem>
                        </>
                    }
                />
            </Menu>
        );
    };

    const handleAcceptClicked = useCallback(
        (quote: QuoteDto) => {
            setSelectedQuote(quote);
            const hasAmendedLineItems = quote?.hasAmendedLineItems;
            if (!isAcceptingQuote && quote?.id) {
                if (!hasAmendedLineItems) {
                    acceptQuote({ params: { id: quote?.id }, payload: [] });
                } else {
                    setIsAcceptLineItemsDialogOpen(true);
                }
            }
        },
        [isAcceptingQuote, acceptQuote]
    );

    useEffect(() => {
        if (isQuoteAcceptSuccess && !jobsLoading) {
            getJobs({
                quoteId: selectedQuote?.id,
                searchText: '',
                sortKey: 'JOB_NUMBER',
                page: 0,
                pageSize: 100000,
                sortAsc: true,
                includeInactive: false,
            }).then(({ data }) => {
                if (data && data.totalQueryResults > 0) {
                    if (selectedQuote?.appraisalType === AppraisalType.Estimate) {
                        window.open(`/job/${data?.pageResults[0].id}/order/create`, '_blank');
                    } else {
                        window.open(`/job/${data?.pageResults[0].id}`, '_blank');
                    }
                } else {
                    setIsJobDialogOpen(true);
                }
            });
        }
    }, [getJobs, isQuoteAcceptSuccess, jobsLoading, navigate, selectedQuote?.appraisalType, selectedQuote?.id]);

    const handleConfirmAcceptQuoteLineItems = useCallback(
        (lineItems: LineItemDto[]) => {
            setIsAcceptLineItemsDialogOpen(false);

            if (selectedQuote?.id) {
                const lineItemIds = lineItems.map((x) => x.id);
                acceptQuote({ params: { id: selectedQuote?.id }, payload: lineItemIds });
            }
        },
        [acceptQuote, selectedQuote?.id]
    );

    const handleCreateJob = useCallback(() => {
        if (selectedQuote?.id) {
            createJob({ ...DEFAULT_JOB, quotes: [{ id: selectedQuote?.id } as QuoteDto] });
        }
    }, [createJob, selectedQuote?.id]);

    const openConfirmDialog = useCallback((quote: QuoteDto, isCopying: boolean) => {
        setSelectedQuote(quote);
        if (isCopying) {
            setIsCopyDialogOpen(true);
        } else {
            setIsCloneDialogOpen(true);
        }
    }, []);

    const handleCloneQuote = useCallback(
        (dto: CloneQuoteDto, isCopy: boolean) => {
            if (isCopy) {
                setIsCopyDialogOpen(false);
                if (selectedQuote?.id) {
                    copyQuote({ ...dto, id: selectedQuote.id });
                }
            } else {
                setIsCloneDialogOpen(false);
                if (selectedQuote?.id) {
                    duplicateQuote({ ...dto, id: selectedQuote.id });
                }
            }
        },
        [copyQuote, duplicateQuote, selectedQuote?.id]
    );

    const handleFilterClicked = useCallback(() => {
        setIsFilterDialogVisible(true);
    }, []);

    const getAdditionalQueryParameters = useCallback(() => {
        const parameters = {};

        if (customerId) {
            parameters['customerId'] = customerId;
        }

        if (quoteFilters) {
            if (quoteFilters.expirationDateAfter) {
                parameters['expirationDateAfter'] = quoteFilters.expirationDateAfter.toISOString();
            }
            if (quoteFilters.expirationDateBefore) {
                parameters['expirationDateBefore'] = quoteFilters.expirationDateBefore.toISOString();
            }
            if (quoteFilters.job) {
                parameters['jobId'] = quoteFilters.job.id;
            }
            if (quoteFilters.quoteDateAfter) {
                parameters['quoteDateAfter'] = quoteFilters.quoteDateAfter.toISOString();
            }
            if (quoteFilters.quoteDateBefore) {
                parameters['quoteDateBefore'] = quoteFilters.quoteDateBefore.toISOString();
            }
            if (quoteFilters.showHistorical) {
                parameters['showHistorical'] = quoteFilters.showHistorical;
            }
        }

        return parameters;
    }, [customerId, quoteFilters]);

    const quoteLabel = useMemo(() => {
        if (selectedQuote?.appraisalType === AppraisalType.Estimate) {
            return 'Estimate';
        } else {
            return 'Quote';
        }
    }, [selectedQuote?.appraisalType]);

    return (
        <>
            {renderAddMenu()}
            {selectedQuote && (
                <AcceptQuoteLineItemsDialog
                    open={isAcceptLineItemsDialogOpen}
                    onClose={() => {
                        setIsAcceptLineItemsDialogOpen(false);
                        setSelectedQuote(undefined);
                    }}
                    onConfirm={(selectedLineItems) => handleConfirmAcceptQuoteLineItems(selectedLineItems)}
                    quote={selectedQuote}
                />
            )}
            {selectedQuote && selectedQuote.appraisalType !== AppraisalType.MasterPriceList && (
                <CloneQuoteDialog
                    open={isCloneDialogOpen}
                    onConfirm={(dto) => handleCloneQuote(dto, false)}
                    onClose={() => setIsCloneDialogOpen(false)}
                    quoteEffectiveOn={new Date(selectedQuote.effectiveOn!)}
                />
            )}
            {selectedQuote && selectedQuote.appraisalType === AppraisalType.MasterPriceList && (
                <CloneQuoteDialog
                    open={isCopyDialogOpen}
                    onConfirm={(dto) => handleCloneQuote(dto, true)}
                    onClose={() => setIsCopyDialogOpen(false)}
                    quoteEffectiveOn={new Date(selectedQuote.effectiveOn!)}
                    isCopying
                />
            )}
            <QuoteFilterDialog
                isVisible={isFilterDialogVisible}
                onClose={() => setIsFilterDialogVisible(false)}
                filterProps={quoteFilters}
                setFilterProps={handleSearchTermFilterChange}
            />
            <ConfirmDialog
                open={isJobDialogOpen}
                onClose={() => setIsJobDialogOpen(false)}
                content={<Typography>Would you like to convert this accepted {quoteLabel} into a new job?</Typography>}
                title={'Create Job'}
                titleIcon={<Article />}
                onConfirm={handleCreateJob}
                confirmText='Confirm'
                requireConfirmation={false}
            />
            <StandardListViewV2
                headerIcon={!dashboardView ? <Assignment /> : <></>}
                headerTitle={!dashboardView ? 'Quotes' : ''}
                breadCrumbs={!dashboardView ? [{ label: 'Home', navLink: '/' }] : []}
                defaultPaginationProps={defaultPaginationProps}
                getDataQuery={useGetQuoteQuery}
                isShowActiveToggleVisible
                inactivateMutation={useQuoteBulkArchiveMutation}
                activateMutation={useQuoteBulkUnarchiveMutation}
                deleteMutation={useArchiveQuoteBulkDeleteMutation}
                tableColumns={tableColumns}
                entityNameSingular='Quote'
                permissionName='quote'
                handleAdd={handleMenuOpen}
                handleEdit={handleTableEdit}
                filterChips={getFilterChips()}
                handleFilterChipDelete={handleRemoveFilterChip}
                handleFilterClicked={handleFilterClicked}
                additionalQueryParameters={getAdditionalQueryParameters()}
                additionalActionItems={[
                    {
                        text: 'CLONE',
                        onClick: (selected: QuoteDto[]) => openConfirmDialog(selected[0], false),
                        enabledCheck: (selected: QuoteDto[]) => selected.length === 1 && !isDuplicatingQuote && selected[0].clonedQuoteId === null && selected[0].appraisalType !== AppraisalType.MasterPriceList,
                        icon: isDuplicatingQuote ? <CircularProgress size={12} color='primary' /> : <ControlPointDuplicate />,
                    },
                    {
                        text: 'COPY TO NEW JOB',
                        onClick: (selected: QuoteDto[]) => openConfirmDialog(selected[0], true),
                        enabledCheck: (selected: QuoteDto[]) => selected.length === 1 && !isCopyingQuote && selected[0].appraisalType === AppraisalType.MasterPriceList,
                        icon: isCopyingQuote ? <CircularProgress size={12} color='primary' /> : <ContentCopy />,
                    },
                    {
                        text: 'ACCEPT',
                        onClick: (selected: QuoteDto[]) => handleAcceptClicked(selected[0]),
                        enabledCheck: (selected: QuoteDto[]) =>
                            selected.length === 1 && !!!selected[0].acceptedOn && !isAcceptingQuote && selected[0].appraisalType === 0,
                        icon: isAcceptingQuote ? <CircularProgress size={12} color='primary' /> : <ThumbUp />,
                    },
                    {
                        text: 'DOWNLOAD PDF',
                        onClick: (selected: QuoteDto[]) => generatePdf(selected[0]?.id),
                        enabledCheck: (selected: QuoteDto[]) => selected.length === 1 && !pdfLoading && selected[0].appraisalType === 0,
                        icon: pdfLoading ? <CircularProgress size={12} color='primary' /> : <DownloadForOffline />,
                    },
                ]}
                additionalMenuItems={[
                    {
                        text: 'Clone',
                        onClick: (selected: QuoteDto[]) => openConfirmDialog(selected[0], false),
                        enabledCheck: (selected: QuoteDto[]) => selected.length === 1 && !isDuplicatingQuote && selected[0].clonedQuoteId === null && selected[0].appraisalType !== AppraisalType.MasterPriceList,
                    },
                    {
                        text: 'Copy To New Job',
                        onClick: (selected: QuoteDto[]) => openConfirmDialog(selected[0], true),
                        enabledCheck: (selected: QuoteDto[]) => selected.length === 1 && !isCopyingQuote && selected[0].appraisalType === AppraisalType.MasterPriceList,
                    },
                    {
                        text: 'Accept',
                        onClick: (selected: QuoteDto[]) => handleAcceptClicked(selected[0]),
                        enabledCheck: (selected: QuoteDto[]) =>
                            selected.length === 1 && !!!selected[0].acceptedOn && !isAcceptingQuote && selected[0].appraisalType === 0,
                    },
                    {
                        text: 'Download PDF',
                        onClick: (selected: QuoteDto[]) => generatePdf(selected[0]?.id),
                        enabledCheck: (selected: QuoteDto[]) => selected.length === 1 && !pdfLoading && selected[0].appraisalType === 0,
                    },
                ]}
            />
        </>
    );
};

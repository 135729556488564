import { Download, Restore } from '@mui/icons-material';
import { Dialog, DialogTitle, Typography, DialogContent, Grid, DialogActions, Button } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { DatePickerWithDataLookup, SiteSelect } from '../../../Components/CommonInputs';
import { SiteDto } from '../../../dtos';
import { useCreateOrderDownloadSelectedOrdersMutation, useLazyGetDispatchOrderManagementCurrentMonthQuery } from '../../../store/generated/generatedApi';
import { format, isValid } from 'date-fns';
import { exportToPdf } from '../../../util';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../Components/CoreLib/library';

export interface IOrderDownloadModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    defaultDate: Date;
}

export const OrderDownloadModal: FC<IOrderDownloadModalProps> = props => {
    const { open, onClose, defaultDate } = props;

    const [site, setSite] = useState<SiteDto>();
    const [date, setDate] = useState<Date>(defaultDate);

    const [getCurrentMonthDispatch, { data, isFetching }] = useLazyGetDispatchOrderManagementCurrentMonthQuery();
    const [daysWithData, setDaysWithData] = useState<string[]>([]);
    const [month, setMonth] = useState(date);

    const [downloadSelectedPdfs, { isSuccess, isError, error, isLoading: isDownloadingPdfs, reset }] = useCreateOrderDownloadSelectedOrdersMutation();

    useSuccessfulActionSnackbar('downloaded', 'order(s)', isSuccess, reset);
    useFailedActionSnackbar('download', 'order(s)', isError, reset, error);

    const handleDownload = useCallback(() => {
        if (!isValid(date)) {
            return;
        }
        downloadSelectedPdfs({
            orderIds: [],
            siteId: site?.id,
            orderDate: date
        }).then((response: any) => {
            if (response.data) {
                let title = 'Orders';
                if (site) {
                    title += '-' + site.code + '-' + site.name;
                }
                if (date) {
                    title += '-' + format(date, 'yyyy-MM-dd');
                }
                exportToPdf(response.data, `${title}.pdf`.replace(/\s+/g, ''));
            }
        })
    }, [date, downloadSelectedPdfs, site]);

    const loadData = useCallback(
        (date: Date) => {
            const formattedDate = format(date, 'yyyy-MM');
            getCurrentMonthDispatch({ startDate: formattedDate });
        },
        [getCurrentMonthDispatch]
    );

    useEffect(() => {
        loadData(month);
    }, [month, loadData]);

    useEffect(() => {
        if (!isFetching) {
            setDaysWithData(data ?? []);
        }
    }, [data, isFetching]);

    const onMonthChange = useCallback((newMonth: Date) => {
        setDaysWithData([]);
        setMonth(newMonth);
    }, []);

    const handleConfirm = useCallback(() => {
        handleDownload();
    }, [handleDownload]);

    const handleOnClose = useCallback(() => {
        setSite(undefined);
        setDate(defaultDate);
        onClose();
    }, [onClose, defaultDate]);

    return (
        <Dialog onClose={handleOnClose} open={open} fullWidth>
            <DialogTitle sx={{ backgroundColor: 'primary', color: '#ffffff' }}>
                <Typography variant='h2'>
                    {<Restore />} Download Order Details
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' mt={2}>
                    <Grid item>
                        <Typography>Select a date and or site to download a filtered list of order line items.</Typography>
                    </Grid>
                    <Grid item container spacing={2} alignItems={'center'}>
                        <Grid item xs={6}>
                            <DatePickerWithDataLookup
                                date={date}
                                updateDate={setDate}
                                daysWithData={daysWithData}
                                handleOnMonthChange={onMonthChange}
                                label={'Order Date'}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SiteSelect
                                selectedSiteId={site?.id ?? ''}
                                handleSelectedSiteChange={(site) => setSite(site)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={onClose} disabled={isDownloadingPdfs}>
                    Close
                </Button>
                <Button startIcon={<Download />} variant='contained' onClick={handleConfirm} disabled={isDownloadingPdfs}>
                    Download
                </Button>
            </DialogActions>
        </Dialog>
    );
}
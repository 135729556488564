import { EventModel } from "@bryntum/scheduler";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { FC } from "react";
import { FormInput, IFormProps } from "../../../../Components/CoreLib/library";
import { useOrderLineItemEditorForm } from "./userOrderLineItemEditorForm";
import { OrderLineItemDto } from "../../../../dtos";

export interface IOrderLineItemEditorProps extends IFormProps<EventModel> {
    open: boolean;
    disabled: boolean;
    orderLineItemDto?: OrderLineItemDto;
}

export const OrderLineItemEditor: FC<IOrderLineItemEditorProps> = (props) => {
    const {
        open,
        disabled,
    } = props;

    const {
        isFormDirty,
        handleSave,
        handleCancel,
        setFormDriverMemo,
        formDriverMemo,
    } = useOrderLineItemEditorForm({ ...props });

    return (
        <Dialog open={open} maxWidth='md' fullWidth>
            <DialogTitle sx={{ backgroundColor: 'primary', color: '#ffffff' }}>
                <Grid container direction='row' alignItems='center' gap={1}>
                    Edit Order Line Item
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' mt={1} spacing={1}>
                    <Grid item xs={12}>
                        <FormInput
                            label='Driver Memo'
                            fullWidth
                            value={formDriverMemo}
                            onChange={(e) => { setFormDriverMemo(e.target.value); }}
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' disabled={!isFormDirty || disabled} onClick={handleSave}>
                    SAVE
                </Button>
                <Button variant='outlined' onClick={handleCancel}>
                    CANCEL
                </Button>
            </DialogActions>
        </Dialog>
    );
}